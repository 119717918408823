import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PAGE_NAMES, ROLES, ROLES_TYPE } from '../../../core/helpers/constants';
import { UtilityService } from '../../../core/helpers/utility.service';
import { IPermission, IUser } from '../../../core/models/auth.models';
import { ITask } from '../../../core/models/tasks.models';
import { IColumn, LoadDataFromServer } from '../../../shared/components/datatable/datatable.model';
import { AppService } from '../../../store/app.service';
import { TasksApiService } from '../tasks.apiservice';
import { TasksService } from '../tasks.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss']
})

export class TasksListComponent implements OnInit {
  doWhat: IPermission = {} as IPermission;
  activeUser: IUser = {} as IUser;
  ROLES = ROLES;
  showCompleted: boolean = false;

  columns: Array<IColumn> = [];
  completedTasksColumns: Array<IColumn> = []
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  allTasksData: Array<ITask> = new Array<ITask>();
  todoTasksData: Array<ITask> = new Array<ITask>();
  completedTasksData: Array<ITask> = new Array<ITask>();
  public userRoles: Array<number> = [];
  showOnlyMyTasks: boolean = true;
  showTasksDue: string = 'All';
  dtForm: FormGroup;
  savedSearchId: any = null;
  private loadEvent!: LoadDataFromServer;
  dataTotalCount: number = 0;
  constructor(
    public uService: UtilityService,
    private appService: AppService,
    private tasksService: TasksService,
    private route: ActivatedRoute,
    private taskApiService: TasksApiService,
    private formBuilder: FormBuilder) {
    this.userRoles = [...this.uService.getUserRoles()];
    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.activeUser = u;
      })

    this.columns.push({ title: 'Title', field: 'title', sortable: true, searchable: true } as IColumn);
    this.columns.push({ title: 'Client', field: 'clientName', sortable: true, searchable: true, anchorLink: true, anchorType: 'client', anchorParamsField: 'clientId' } as IColumn);
    this.columns.push({ title: 'Campaign', field: 'campaignName', sortable: true, searchable: true, anchorLink: true, anchorType: 'campaign', anchorParamsField: 'campaignId' } as IColumn);
    this.columns.push({ title: 'Due Date', field: 'dueDate', sortable: true, pipe: 'P_AppDateFormat', pipeArgs: ['MM/DD/YYYY'] } as IColumn);
    this.columns.push({ title: 'Assigned User', field: 'assignedUserName', sortable: true, searchable: true, visible: (this.userRoles.includes(ROLES.Admin.Code)) || this.activeUser.type === ROLES_TYPE.Internal } as IColumn);


    this.completedTasksColumns.push({ title: 'Title', field: 'title', sortable: true, searchable: true } as IColumn);
    this.completedTasksColumns.push({ title: 'Client', field: 'clientName', sortable: true, searchable: true, anchorLink: true, anchorType: 'client', anchorParamsField: 'clientId' } as IColumn);
    this.completedTasksColumns.push({ title: 'Campaign', field: 'campaignName', sortable: true, searchable: true, anchorLink: true, anchorType: 'campaign', anchorParamsField: 'campaignId' } as IColumn);
    this.completedTasksColumns.push({ title: 'Date Completed', field: 'completedOn', sortable: true, pipe: 'P_AppDateFormat', pipeArgs: ['YYYY/MM/DD', true] } as IColumn);
    this.completedTasksColumns.push({ title: 'Assigned User', field: 'assignedUserName', sortable: true, searchable: true, visible: this.userRoles.includes(ROLES.Admin.Code) || this.activeUser.type === ROLES_TYPE.Internal } as IColumn);

    this.dtForm = this.formBuilder.group({
      showTasksDue: [this.showTasksDue]
    });
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Tasks');
    this.savedSearchId = this.route.snapshot.queryParams['savedsearchid'];
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Tasks);
    this.appService.getAllTasks$()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        this.todoTasksData = response.data;
        this.dataTotalCount = response.totalCount;
      });

    this.appService.getForceLoadingTasks()
      .pipe(takeUntil(this.destroyed$)).subscribe((res: Boolean) => {
        if (res) {
          this.loadData();
          this.appService.setForceLoadingTasks(false);
        }
      })
    this.configureTasksService();
  }

  configureTasksService() {
    this.tasksService.onSave.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadData();
        }
      });
    this.tasksService.onDelete.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadData();
        }
      });
    this.tasksService.onMarkAsComplete.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadData();
        }
      });
  }

  onLoadingData(event: LoadDataFromServer) {
    this.loadEvent = event;
    this.loadData();
  }

  editRow(evt: any) {
    evt.allTasksloadingParameters = this.getLoadingParameters(this.showCompleted);
    this.tasksService.openEditTaskModal(evt);
  }

  deleteRows(evt: any) {
    this.taskApiService.deleteTask(evt.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.loadData();
      })
  }

  onCheckBoxChange(params: any) {
    this.taskApiService.markTask({ id: params.data.id, isCompleted: params.evt.currentTarget.checked })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.loadData();
      })
  }

  onShowMyTasksChange() {
    this.loadData();
  }

  onDueDateFilterChange() {
    this.showTasksDue = this.dtForm.get('showTasksDue')?.value;
    this.loadData();
  }

  getLoadingParameters(showCompleted: boolean) {
    var yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - (offset*60*1000))
    let todayAtUserComputer = yourDate.toISOString().split('T')[0];
    return showCompleted ? {
      savedSearchId: this.savedSearchId,
      showOnlyMyTasks: this.showOnlyMyTasks,
      dueDate: this.showTasksDue,
      completion: 'Completed',
      todayAtUserComputer,
      ...this.loadEvent
    } : {
      savedSearchId: this.savedSearchId,
      showOnlyMyTasks: this.showOnlyMyTasks,
      dueDate: this.showTasksDue,
      completion: 'ToDo',
      todayAtUserComputer,
      ...this.loadEvent
    };
  }

  loadData() {
    this.appService.fetchAllTasks(this.getLoadingParameters(this.showCompleted));
  }

  showCompletedTasks() {
    this.showCompleted = !this.showCompleted;

    this.appService.fetchAllTasks(this.getLoadingParameters(this.showCompleted));
  }

  toggleTab(showCompleted: boolean) {
    this.showCompleted = showCompleted;
    this.loadData();
  }
}

<div class="modal-box" cdkDrag>
    <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
        <h4 class="modal-title">Merge Campaigns</h4>
        <h5 class="modal-title" *ngIf="step==1">Step 1 - Select Values.</h5>
        <h5 class="modal-title" *ngIf="step==2">Step 2 - Confirm and Merge.</h5>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="addCampaignForm" (ngSubmit)="onSubmit()">
        <div class="modal-body" *ngIf="step==1">
            <table class="table tbl-client">
                <thead>
                    <tr>
                        <th class="label"><label>Primary Record?</label></th>
                        <th *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" value="{{ campaign.id }}" formControlName="id"
                                    (click)="setPrimaryRecord(campaign.id)" style="height: 16px; width: 16px;">
                            </label>
                            <button type="button" (click)="setPrimaryRecord(campaign.id)" class="btn btn-link">
                                Select All
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th class="label"><label>Campaign Name</label></th>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.name" formControlName="name"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ campaign.name }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Street</label></td>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.street" formControlName="street"
                                    style="height: 12px; width: 12px;">
                                    <span> &nbsp; {{ campaign.street }}</span>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="label"><label>City</label></td>
                            <td *ngFor="let campaign of campaigns">
                                <label class="radio-label">
                                    <input type="radio" [value]="campaign.city" formControlName="city"
                                        style="height: 12px; width: 12px;">
                                    <span> &nbsp; {{ campaign.city }}</span>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="label"><label>State</label></td>
                            <td *ngFor="let campaign of campaigns">
                                <label class="radio-label">
                                    <input type="radio" [value]="campaign.state" formControlName="state"
                                        style="height: 12px; width: 12px;">
                                    <span> &nbsp; {{ campaign.state }}</span>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="label"><label>Zipcode</label></td>
                            <td *ngFor="let campaign of campaigns">
                                <label class="radio-label">
                                    <input type="radio" [value]="campaign.zipcode" formControlName="zipcode"
                                        style="height: 12px; width: 12px;">
                                    <span> &nbsp; {{ campaign.zipcode }}</span>                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Phone #</label></td>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.locationPhone" formControlName="locationPhone"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ campaign.locationPhone }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Email</label></td>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.locationEmail" formControlName="locationEmail"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ campaign.locationEmail }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Platform</label></td>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.platform" formControlName="platform"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ campaign.platform }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Stage</label></td>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.stageId" formControlName="stageId"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ getStageTitle(campaign.stageId) }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>CRM ID</label></td>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.crmId" formControlName="crmId"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ campaign.crmId }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>CRM Name</label></td>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.crmName" formControlName="crmName"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ campaign.crmName }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Client</label></td>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.clientId" formControlName="clientId"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ campaign.clientName }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>DMS</label></td>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.dmsId" formControlName="dmsId"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ campaign.dmsName }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Additional Info</label></td>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.opportunityNotes" formControlName="opportunityNotes"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ campaign.opportunityNotes }}</span>
                            </label>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td class="label"><label>Platform Campaign ID</label></td>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.platformCampaignId " formControlName="platformCampaignId"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ campaign.platformCampaignId }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="label"><label>Platform Account ID</label></td>
                        <td *ngFor="let campaign of campaigns">
                            <label class="radio-label">
                                <input type="radio" [value]="campaign.platformAccountId" formControlName="platformAccountId"
                                    style="height: 12px; width: 12px;">
                                <span> &nbsp; {{ campaign.platformAccountId }}</span>
                            </label>
                        </td>
                    </tr> -->
                </tbody>
            </table> 
        </div>
        <div class="modal-footer" *ngIf="step==1" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <button type="button" class="btn btn-secondry" style="border: 1px solid red; border-radius: 4px; padding: 8px; color: red;" (click)="activeModal.close('Close click')">Cancel</button>
            <button [disabled]="!(addCampaignForm.value.id)" type="button" class="blue-button" (click)="nextStep()" title="Please select primary record to proceed!">NEXT</button>
        </div>
        <div class="modal-body" *ngIf="step == 2">
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="name">Campaign Name</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="name"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                        placeholder="Enter campaign name">
                </div>
            </div>
            <div *ngIf="submitted && f.name.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.name.errors.required">
                        Name is required
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="street">Street</label>
                <div class="col-sm-4">
                    <textarea rows="3" class="form-control" formControlName="street"
                        [ngClass]="{ 'is-invalid': submitted && f.street.errors }"
                        placeholder="Enter location street"></textarea>
                </div>
            </div>
            <div *ngIf="submitted && f.street.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.street.errors.max">Stret Cannot be greater than 200 characters</div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="city">City</label>
                <div class="col-sm-4">
                    <textarea rows="3" class="form-control" formControlName="city"
                        [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                        placeholder="Enter location city"></textarea>
                </div>
            </div>
            <div *ngIf="submitted && f.city.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.city.errors.max">City Cannot be greater than 200 characters</div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="state">State</label>
                <div class="col-sm-4">
                    <textarea rows="3" class="form-control" formControlName="state"
                        [ngClass]="{ 'is-invalid': submitted && f.state.errors }"
                        placeholder="Enter location state"></textarea>
                </div>
            </div>
            <div *ngIf="submitted && f.state.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.state.errors.max">State Cannot be greater than 200 characters</div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="zipcode">Zipcode</label>
                <div class="col-sm-4">
                    <textarea rows="3" class="form-control" formControlName="zipcode"
                        [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }"
                        placeholder="Enter location zipcode"></textarea>
                </div>
            </div>
            <div *ngIf="submitted && f.zipcode.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.zipcode.errors.max">Zipcode Cannot be greater than 10 characters</div>
                </div>
            <!-- <div class="row mb-3">
                <label class="col-sm-3" for="locationAddress">Address</label>
                <div class="col-sm-4">
                    <textarea rows="3" class="form-control" formControlName="locationAddress"
                        [ngClass]="{ 'is-invalid': submitted && f.locationAddress.errors }"
                        placeholder="Enter location address"></textarea>
                </div>
            </div>
            <div *ngIf="submitted && f.locationAddress.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.locationAddress.errors.required">Address is required </div>
                    <div *ngIf="f.locationAddress.errors.max">Cannot be greater than 200 characters</div>
                </div> -->
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="locationPhone">Phone#</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="locationPhone"
                        [ngClass]="{ 'is-invalid': submitted && f.locationPhone.errors }"
                        placeholder="Enter contact phone number">
                </div>
            </div>
            <div *ngIf="submitted && f.locationPhone.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.locationPhone.errors.required">Phone number is required</div>
                    <div *ngIf="f.locationPhone.errors.max">Cannot be greater than 20 characters</div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="locationEmail">Email</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="locationEmail"
                        [ngClass]="{ 'is-invalid': submitted && f.locationEmail.errors }"
                        placeholder="Enter contact email">
                </div>
            </div>
            <div *ngIf="submitted && f.locationEmail.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.locationEmail.errors.required">Location Email is required</div>
                    <div *ngIf="f.locationEmail.errors.max">Cannot be greater than 50 characters</div>
                    <div *ngIf="f.locationEmail.errors.email">Invalid email address</div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="platform">Platform</label>
                <div class="col-sm-4">
                    <ng-select [ngClass]="{ 'is-invalid': submitted && f.platform.errors }"
                        formControlName="platform">
                        <ng-option *ngFor="let p of allPlatforms" [value]="p">{{p}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div *ngIf="submitted && f.platform.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.platform.errors.required">platform is required</div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="stageId">Stage Id</label>
                <div class="col-sm-3">
                    <ng-select [ngClass]="{ 'is-invalid': submitted && f.stageId.errors }"
                        formControlName="stageId">
                        <ng-option *ngFor="let stage of CAMPAIGN_STAGES" [value]="stage.value">{{stage.title}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div *ngIf="submitted && f.stageId.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.stageId.errors.required">Stage is required</div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="crmId">CRM ID</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="crmId"
                        [ngClass]="{ 'is-invalid': submitted && f.crmId.errors }" placeholder="Enter CRM ID">
                </div>
            </div>
            <div *ngIf="submitted && f.crmId.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.crmId.errors.required">CRM ID is required</div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="crmName">CRM Name</label>
                <div class="col-sm-4">
                    <ng-select *ngIf="!isCustomCrmName" formControlName="crmName">
                        <ng-option *ngFor="let c of defaultCRMNames" [value]="c.value">{{c.title}}
                        </ng-option>
                    </ng-select>
                    <input *ngIf="isCustomCrmName" type="text" class="form-control" formControlName="crmName"
                        [ngClass]="{ 'is-invalid': submitted && f.crmName.errors }"
                        placeholder="Enter CRM Name">
                </div>
                <div *ngIf="isCustomCrmName" class="col-md-1">
                    <i-feather name="corner-right-up" (click)="onShowDropdown()" title="Show Dropdown"
                        class="icon-sm icon"></i-feather>
                </div>
            </div>
            <div *ngIf="submitted && f.crmName.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.crmName.errors.required">CRM name is required</div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 center-label" for="clientId">Client</label>
                <div class="col-sm-3">
                    <ng-select [ngClass]="{ 'is-invalid': submitted && f.clientId.errors }"
                        (change)="updateProspectClientDetails()" formControlName="clientId">
                        <ng-option *ngFor="let c of allClients" [value]="c.id">{{c.firstName}} {{c.lastName}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div *ngIf="submitted && f.clientId.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.clientId.errors.required">Client is required</div>
                </div>
            </div>
            <div class="row mb-3" [ngClass]="{'disabled' : options !==null && !options.canAssign}">
                <label class="col-sm-3 center-label" for="dmsId">Dms</label>
                <div class="col-sm-3">
                    <ng-select [ngClass]="{ 'is-invalid': submitted && f.dmsId.errors }"
                        formControlName="dmsId">
                        <ng-option *ngFor="let rep of allDms" [value]="rep.id">{{rep.firstName}}
                            {{rep.lastName}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div *ngIf="submitted && f.dmsId.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.dmsId.errors.required">DMS is required</div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="opportunityNotes">Additional Info</label>
                <div class="col-sm-4">
                    <textarea rows="3" class="form-control" formControlName="opportunityNotes"
                        [ngClass]="{ 'is-invalid': submitted && f.opportunityNotes.errors }"
                        placeholder="Enter Additional Info"></textarea>
                </div>
            </div>
            <div *ngIf="submitted && f.opportunityNotes.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.opportunityNotes.errors.required">Additional Info is required
                    </div>
                </div>
            </div>
            <!-- <div *ngIf="!isExternal || isAdmin" class="row mb-3">
                <label class="col-sm-3 center-label" for="platformCampaignId">Platform Campaign ID</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="platformCampaignId"
                        [ngClass]="{ 'is-invalid': submitted && f.platformCampaignId.errors }"
                        placeholder="Enter Platform Campaign ID">
                </div>
            </div>
            <div *ngIf="submitted && f.platformCampaignId.errors" class="row mb-minus-3 row-validation">
                <div class="col-sm-3"> </div>
                <div class="col-sm-9">
                    <div *ngIf="f.platformCampaignId.errors.required">Platform Campaign ID is required</div>
                </div>
            </div> -->
            <!-- <div *ngIf="!isExternal || isAdmin" class="row mb-3">
                <label class="col-sm-3 center-label" for="platformAccountId">Platform Account ID</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="platformAccountId"
                        [ngClass]="{ 'is-invalid': submitted && f.platformAccountId.errors }"
                        placeholder="Enter Platform Account ID">
                </div>
            </div>
            <div *ngIf="submitted && f.platformAccountId.errors" class="row mb-minus-3 row-validation">
                <div *ngIf="f.platformAccountId.errors.required">Platform Account ID is required</div>
            </div> -->
        </div>
        <div class="modal-footer" *ngIf="step==2" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <button type="button" class="btn btn-secondry" style="border: 1px solid red; border-radius: 4px; padding: 8px; color: red;" (click)="activeModal.close('Cross click')">Cancel</button>
            <div style="display: flex; flex-direction: row; justify-content: end;">
                <button type="button" class="blue-button" style="max-width: 55.97px !important; min-width: 0px !important;" (click)="previousStep()">Back</button>
                <button type="submit" class="btn btn-warning" style="margin-left: 8px; box-shadow: none !important;">Save</button>
            </div>
        </div>
    </form>
</div>
<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">
  <ngx-simplebar class="h-100">
    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu">
        <ng-container *ngFor="let item of menuItems">
          <li class="menu-title" *ngIf="item.isTitle">{{ (item.label | translate) | uppercase }}</li>
          <li *ngIf="!item.isTitle && !item.isLayout">
            <a [routerLink]="item.link" *ngIf="item.link && !hasItems(item) && !item.openOnNewPage" class="side-nav-link-ref" routerLinkActive="active"
              [target]="item.openOnNewPage ? '_blank': '_self'">
              <i-feather *ngIf="item.icon" name="{{ item.icon }}"></i-feather>
              <i *ngIf="item.faIcon" class="{{item.faIcon}}" style="font-size: initial; min-width: 1.5em;text-align: center;"></i>
              <span>{{ item.label | translate }}</span>
              <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text | translate}}</span>
            </a>
            <a [href]="item.link" *ngIf="item.link && !hasItems(item) && item.openOnNewPage"
            class="side-nav-link-ref" routerLinkActive="active" [target]="item.openOnNewPage ? '_blank': '_self'">
              <i-feather *ngIf="item.icon" name="{{ item.icon }}"></i-feather>
              <i *ngIf="item.faIcon" class="{{item.faIcon}}" style="font-size: initial; min-width: 1.5em;text-align: center;"></i>
              <span>{{ item.label | translate }}</span>
              <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text| translate}}</span>
            </a>
            <a [routerLink]="" (click)="menuClicked(item.name)" *ngIf="!item.link" class="side-nav-link-ref"
              [target]="item.openOnNewPage ? '_blank': '_self'">
              <i-feather *ngIf="item.icon" name="{{ item.icon }}"></i-feather>
              <i *ngIf="item.faIcon" class="{{item.faIcon}}" style="font-size: initial; min-width: 1.5em;text-align: center;"></i>
              <span>{{ item.label | translate }}</span>
              <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text | translate}}</span>
            </a>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="hasSpecialRoles()" class="alert alert-dark info-text">
        Subscriptions, Orders, Users & Payment Methods can be found under My Account
      </div>
    </div>
    <div id="contact-us" style="text-align: center;margin-bottom: 40px;">
      Need Help? <br><a target="_blank" style="color: #495057;" href="https://franchisefocusedmarketing.com/reach-feedback-form/">Contact Us</a>
    </div>
    <!-- Sidebar -->
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
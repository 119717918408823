<ul *ngIf="!externalUser" class="tab-titles" style="border-bottom: 2px solid white;">
    <li *ngFor="let tab of tabs; let t = index;" (click)="selectTab(tab)" [ngStyle]="{'margin-left' : (t !== 0) ? '-15px' : '0px', 'z-index' : (activeTab === tab) ? '5' : '0'}" [ngClass]="(activeTab === tab) ? 'activeLI' : 'inactiveLI'"><h5 style="margin-bottom: 0px !important;">{{ tab }}</h5></li>
</ul>
<div style="background-color: white; padding-top: 16px; padding-bottom: 8px; padding-left: 8px; padding-right: 8px;" [ngStyle]="{'display': ((activeTab === 'Tasks') && !externalUser) ? 'block' : 'none'}">
    <div fxLayout="row" fxLayoutAlign="space-between" style="padding-left: 16px;">
        <h2>TO-DO</h2>
    </div>
    <div class="card-body">
        <app-tasks-datatable [iSortColumn]="'title'" [iShowActions]="userRoles.includes(ROLES.Admin.Code)"
            (oRowClick)="editRow($event)" [iUserRoles]="userRoles" (oCBClick)="onCheckBoxChange($event)"
            [iHideHeaderActions]="true" [iColumns]="columns" (oDeleteRows)="deleteRows($event)"
            (oEditRow)="editRow($event)" [iData$]="todoTasksData" [iIsServerLoading]="true" [iTotalCount]="dataTotalCount" 
            (oLoadDataFromServer)="onLoadingData($event)"></app-tasks-datatable>
    </div>
</div>
<div style="background-color: white;">
    <iframe allow="clipboard-read; clipboard-write" *ngIf="((activeTab === 'Dashboard') && !externalUser) || (activeTab === 'Sales Pipeline') || (activeTab === 'DMS Dash')"
    [style.height]="getHeight" style='width: 100%;'[src]="iframeSrc" scrolling="yes"></iframe>
</div>
<div style='margin-top: 20px;' *ngIf="(activeTab === 'Widgets')" class="d-flex flex-wrap" [sortablejs]="widgets" [sortablejsOptions]="sortableOptions">
    <ng-container *ngFor="let widget of widgets;">
        <app-widget [widget]="widget" [id]='widget.id' style="min-width: 400px;"></app-widget>
    </ng-container>
</div>
<div *ngIf="(activeTab === 'Manager Dash') || externalUser" style="padding: 16px;">
    <div class="mm-dash-row">
        <div class="mm-dash-cell">
            <h5 style="background-color: black; color: white; text-align: center; padding: 15px; border-radius: 0.25rem 0.25rem 0 0; width: 100%;">Summary</h5>
            <div style="padding: 8px 8px 32px 8px; max-height: 421px; overflow: auto; width: 100%;">
                <div style="display: flex; flex-direction: column; align-items: center; padding-bottom: 16px;">
                    <h5 style="width: 100%; text-align: center;">Total Campaigns:</h5>
                    <div style="padding: 0px 16px 0px 16px;">
                        <h3 style="text-align: center; margin-top: 8px; margin-bottom: 0px !important;">{{ totalActiveCampaignCount }}</h3>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column; align-items: center; padding-bottom: 16px;">
                    <h5 style="width: 100%; text-align: center;">Total Meta Campaigns:</h5>
                    <div style="padding: 0px 16px 0px 16px;">
                        <h3 style="text-align: center; margin-top: 8px; margin-bottom: 0px !important;">{{ totalFacebookCampaignCount }}</h3>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column; align-items: center; padding-bottom: 16px;">
                    <h5 style="width: 100%; text-align: center;">Total Google Campaigns:</h5>
                    <div style="padding: 0px 16px 0px 16px;">
                        <h3 style="text-align: center; margin-top: 8px; margin-bottom: 0px !important;">{{ totalGoogleCampaignCount }}</h3>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column; align-items: center; padding-bottom: 16px;">
                    <h5 style="width: 100%; text-align: center;">Total # Of Clients:</h5>
                    <div style="padding: 0px 16px 0px 16px;">
                        <h3 style="text-align: center; margin-top: 8px; margin-bottom: 0px !important;">{{ this.totalClientCount }}</h3>
                    </div>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center; padding-bottom: 16px; height: 92px; justify-content: flex-end;">
                <p style="font-size: 12px; text-align: center; width: 98%; max-width: 100% !important;">Total campaigns include all active campaigns as well as those currently in the setup phases.</p>
            </div>
        </div>
        <div *ngIf="campValues.length > 0" class="mm-dash-cell">
            <h5 style="background-color: black; color: white; text-align: center; padding: 15px; border-radius: 0.25rem 0.25rem 0 0; width: 100%;">Stages</h5>
            <div style="padding: 8px 8px 32px 8px; max-height: 421px; overflow: auto; width: 100%;">
                <div style="width: 100%; padding-bottom: 16px;">
                    <div *ngFor="let item of campValues;" style="display: flex; flex-direction: column; align-items: center; padding-bottom: 16px;">
                        <h5 style="width: 100%; text-align: center;">{{ item.state }} Campaigns: </h5>
                        <div style="padding: 0px 16px 0px 16px;">
                            <h3 style="text-align: center; margin-top: 8px; margin-bottom: 0px !important;">{{ item.count }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="(this.leadsData.length > 0)" class="mm-dash-cell">
            <h5 style="background-color: black; color: white; text-align: center; padding: 15px; border-radius: 0.25rem 0.25rem 0 0; width: 100%;">Leads</h5>
            <div style="padding: 8px 8px 32px 8px; height: 100% !important; overflow: auto; width: 100%;">
                <div style="display: flex; flex-direction: column; align-items: center; padding-bottom: 16px;">
                    <h5 style="width: 100%; text-align: center;">Total # Of Leads:</h5>
                    <div style=" padding: 0px 16px 0px 16px; ">
                        <h3 style="text-align: center; margin-top: 8px; margin-bottom: 0px !important;">{{ this.leadsData.length }}</h3>
                    </div>
                </div>
                <!-- <div *ngFor="let item of leadValues;" style="display: flex; flex-direction: column; align-items: center; padding-bottom: 16px;">
                    <h5 style="width: 100%; text-align: center;">{{ item.state }} Leads: </h5>
                    <div style="padding: 0px 16px 0px 16px; ">
                        <h3 style="text-align: center; margin-top: 8px; margin-bottom: 0px !important;">{{ item.count }}</h3>
                    </div>
                </div> -->
            </div>
            <div style="display: flex; flex-direction: column; align-items: center; padding-bottom: 16px;">
                <p style="font-size: 12px; text-align: center; width: 98%; max-width: 100% !important;">Lead metrics are displayed if REACH is actively collecting leads. If your brand does not permit the storage of personally identifiable information (PII), this will not be reflected in the system. Please use the Rollup Reporting widget for the most accurate campaign performance metrics.</p>
            </div>
        </div>
        <div *ngIf="!tooManyCompanies" class="mm-dash-cell">
            <h5 style="background-color: black; color: white; text-align: center; padding: 15px; border-radius: 0.25rem 0.25rem 0 0; width: 100%;">Reporting</h5>
            <div style="padding: 8px; max-height: 289px; height: 100%; overflow: auto; width: 100%;">
                <form [formGroup]="reportForm" (submit)="onFormSubmit()" style="display: flex; height: 100%; flex-direction: column; justify-content: space-between;">
                    <div>
                        <div *ngIf="!isEmptyCompanyGuid" class="g-3 align-items-center mb-3">
                            <label class="col-6 mb-2">Company Name</label>
                            <div class="col-6 mb-2">{{ companyData.name }}</div>
                            <label class="col-6 mb-2">Platforms</label>
                            <div class="col-auto">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" value="All" name="platforms" formControlName="platforms"
                                        id="AllPlatforms" checked (change)="handlePlatformsChange($event, 'All')">
                                    <label class="form-check-label" for="AllPlatforms">
                                        All
                                    </label>
                                </div>
                                <div *ngFor="let platform of platforms" class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" [value]="platform" name="platforms"
                                        formControlName="platforms" id="platform_{{ platform }}"
                                        (change)="handlePlatformsChange($event, platform)">
                                    <label class="form-check-label" for="platform_{{ platform }}">
                                        {{ platform }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row g-3 align-items-center mb-3">
                            <label class="col-12 mb-2">Please select report period</label>
                            <div class="col-auto" style="margin-top: 0px !important;">
                                <select class="form-select mb-2" aria-label="Please select period." (change)="onReportPeriodChange(t.value)"
                                    #t>
                                    <option selected disabled>Please select period.</option>
                                    <option type="button" ngbDropdownItem *ngFor="let p of reportPeriods"
                                        [selected]="selectedReportPeriod==p.key">
                                        {{ p.key }}
                                    </option>
                                </select>
                            </div>
                            <label class="col-2 mb-2"></label>
                        </div>
                    </div>
                    <div class="col-auto">
                        <button type="submit" class="blue-button" style="width: 100% !important;">View Rollup Report</button>
                    </div>
                </form>
            </div>
        </div>
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" style="width: 24% !important;">
            <li [ngbNavItem]="1">
            </li>
            <li [ngbNavItem]="3" style="width: 48% !important;">
                <ng-template ngbNavContent style="background-color: white;">
                    <div class="la-ball-spin-clockwise la-dark la-3x" *ngIf="!loadCompleted">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                     <div style="background-color: white; border-radius: 0.25rem;">
                        <h5 style="background-color: black; color: white; text-align: center; padding: 15px; border-radius: 0.25rem 0.25rem 0 0; margin-bottom: 16px !important;">Rollup Report</h5>
                        <iframe #iframe style="height: 99vh; padding: 8px;" width="100%" (load)="onFrameLoadComplete()"></iframe>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" style="background-color: transparent; width: 100%;"></div>
    </div>
</div>

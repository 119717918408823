<div class="dv-custom-navs">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" (activeIdChange)="onTabChange($event)">
        <li [ngbNavItem]="1" [ngStyle]="{'z-index' : (active === 1) ? '5' : '0'}">
            <a ngbNavLink (click)="navigateTo('campaign-report')">
                <i-feather name="check"></i-feather><label>Campaign Report</label>
            </a>
        </li>
        <li [ngbNavItem]="2"[ngStyle]="{'z-index' : (active === 2) ? '5' : '0', 'left': '-15px'}">
            <a ngbNavLink (click)="navigateTo('rollup-report')">
                <i-feather name="file-text"></i-feather><label>Rollup Report</label>
            </a>
        </li>
        <li [ngbNavItem]="3" *ngIf="isAdmin" [ngStyle]="{'z-index' : (active === 3) ? '5' : '0', 'left': '-30px'}">
            <a ngbNavLink (click)="navigateTo('weekly-reports')">
                <i-feather name="file-text"></i-feather><label>Weekly Reports</label>
            </a>
        </li>
        <li [ngbNavItem]="4" *ngIf="hasCmoDashboardAccess" [ngStyle]="{'z-index' : (active === 4) ? '5' : '0', 'left': '-30px'}">
            <a ngbNavLink (click)="navigateTo('brand-report')">
                <i-feather name="file-text"></i-feather><label>Brand Dashboard</label>
            </a>
        </li>
    </ul>
    
    <!-- Router outlet for displaying selected tab's component -->
    <router-outlet></router-outlet>
</div>
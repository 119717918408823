import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { CountToModule } from 'angular-count-to';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SimplebarAngularModule } from 'simplebar-angular';
import { LightboxModule } from 'ngx-lightbox';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../shared/widget/widget.module';
import { PagesRoutingModule } from './pages-routing.module';
import { ClientsApiService } from './clients/clients.apiservice';
import { ClientsMainComponent } from './clients/clients-main/clients-main.component';
import { ClientDetailsComponent } from './clients/client-details/client-details.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { CampaignsApiService } from './campaigns/campaigns.apiservice';
import { CampaignsMainComponent } from './campaigns/campaigns-main/campaigns-main.component';
import { EditCampaignComponent } from './campaigns/edit-campaign/edit-campaign.component';
import { CampaignDetailsComponent } from './campaigns/campaign-details/campaign-details.component';
import { CampaignAuditComponent } from './campaigns/campaign-audit/campaign-audit.component';
import { GlobalSearchModule } from './global-search/global-search.module';
import { ClientReportingUpdateModalComponent } from './clients/client-reporting-update/client-reporting-update.component';
import { ClientsService } from './clients/clients.service';
import { LeadsService } from './leads/leads.service';
import { TagsMainComponent } from './tags/tags-main/tags-main.component';
import { EditTagComponent } from './tags/edit-tag/edit-tag.component';
import { TagsApiService } from './tags/tags.apiservice';
import { DatatableModule } from '../shared/components/datatable/datatable.module';
import { SearchesModule } from './searches/searches.module';
import { TasksListComponent } from './tasks/tasks-list/tasks-list.component';
import { TasksApiService } from './tasks/tasks.apiservice';
import { TasksService } from './tasks/tasks.service';
import { UsersApiService } from './users/users.apiservice';
import { UsersService } from './users/users.service';
import { UsersMainComponent } from './users/users-main/users-main.component';
import { CompanyMainComponent } from './companies/company-main/company-main.component';
import { CompanyApiService } from './companies/company.apiservice';
import { CompanyService } from './companies/company.service';
import { MergeClientsModal } from '../shared/modals/merge-clients/merge-clients.component';
import { MergeCampaignsModal } from '../shared/modals/merge-campaign/merge-campaign.component';
import { ClientReportingUpdateSelectCampaignsModalComponent } from './clients/client-reporting-update-select-campaigns-modal/client-reporting-update-select-campaigns-modal.component';
import { CampaignDetailsReportViewComponent } from './campaigns/campaign-details-report-view/campaign-details-report-view.component';
import { LeadsModule } from './leads/leads.module';
import { NotesModule } from '../shared/components/notes/notes.module';
import { CampaignsService } from './campaigns/campaigns.service';
import { CouponsMainComponent } from './coupons/coupons-main/coupons-main.component';
import { EditCouponComponent } from './coupons/edit-coupon/edit-coupon.component';
import { CouponsApiService } from './coupons/coupon.apiservice';
import { CouponsService } from './coupons/coupon.service';
import { SubscriptionSharedComponent } from '../shared/components/subscriptions/subscription.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ShopModule } from './shop/shop.module';
import { AccountService } from '../account/account.service';
import { ProductsApiService } from './products/products-api.service'; 
import { ProspectsMainComponent } from './clients/prospects-main/prospects-main.component';
import { ProspectDetailsComponent } from './clients/prospect-details/prospect-details.component';     
import { ReportsApiService } from './reports/reports.apiservice';
import { PageNotFoundComponent } from '../shared/components/404/404.component';
import { SubscriptionApiService } from './subscriptions/subscription.apiservice';
import { OrdersApiService } from './orders/orders.apiservice';
import { PaymentMethodApiService } from './my-account/payment-method/payment-method.apiservice';
import { SpecialDatatableModule } from "../shared/components/special-datatable/special-datatable.module";
import { CampaignDatatableModule } from "../shared/components/campaign-datatable/campaign-datatable.module";
import { TasksDatatableModule } from "../shared/components/tasks-datatable/tasks-datatable.module";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReportsModule } from './reports/reports.module';

@NgModule({
    declarations: [
        ClientsMainComponent,
        ProspectsMainComponent,
        ProspectDetailsComponent,
        CampaignsMainComponent,
        CompanyMainComponent,
        CampaignDetailsComponent,
        TasksListComponent,
        CampaignAuditComponent,
        EditCampaignComponent,
        ClientDetailsComponent,
        ClientReportingUpdateModalComponent,
        TagsMainComponent,
        EditTagComponent,
        UsersMainComponent,
        MergeClientsModal,
        MergeCampaignsModal,
        ClientReportingUpdateSelectCampaignsModalComponent,
        CampaignDetailsReportViewComponent,
        CouponsMainComponent,
        EditCouponComponent,
        SubscriptionSharedComponent,
        PageNotFoundComponent
    ],
    providers: [
        ClientsService,
        LeadsService,
        CampaignsService,
        UsersService,
        TasksService,
        CompanyService,
        ClientsApiService,
        CampaignsApiService,
        TagsApiService,
        TasksApiService,
        UsersApiService,
        ProductsApiService,
        SubscriptionApiService,
        OrdersApiService,
        PaymentMethodApiService,
        CompanyApiService,
        CouponsApiService,
        CouponsService,
        AccountService,
        ReportsApiService
    ],
    imports: [
        NgxUiLoaderModule,
        CommonModule,
        WidgetModule,
        CountToModule,
        SharedModule,
        PagesRoutingModule,
        SimplebarAngularModule,
        NgbNavModule,
        LightboxModule,
        DashboardModule,
        GlobalSearchModule,
        DatatableModule,
        SearchesModule,
        LeadsModule,
        NotesModule,
        ShopModule,
        SpecialDatatableModule,
        CampaignDatatableModule,
        TasksDatatableModule,
        DragDropModule,
        ReportsModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }

// Table data
export interface Table {
  id: number;
  firstName: string;
  lastName: string;
  brand: string;
  dms: string;
  salesRep: string;
  phone: string;
  email: string;
  notes: string;
}


export interface IColumn {
  type?: string,
  title: string,
  field: string,
  sortable?: boolean,
  searchable?: boolean,
  anchorLink?: boolean,
  anchorType?: string,
  anchorParamsField?: string,
  visible?: boolean
}

export interface LoadDataFromServer {
  pageSize: number,
  pageNumber: number,
  sortColumn: string,
  sortDirection: sortDirection,
  search: string,
  startDate: any,
  endDate: any,
  stageId?: number
}

export enum sortDirection {
  asc = 'asc',
  desc = 'desc'
}
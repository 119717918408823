<div class="modal-box" cdkDrag>
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
        <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <h4 class="modal-title">Edit Subscription</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mb-3" *ngIf="section !== 'products'">
                <label class="col-sm-3" for="paymentMethod">Payment Method</label>
                <div class="col-sm-9">
                    <ng-select formControlName="paymentMethodId"
                        [ngClass]="{ 'is-invalid': submitted && f.paymentMethodId.errors }" bindLabel="name" bindValue="id"
                        [items]="allPaymentMethods">
                    </ng-select>
                    <div *ngIf="submitted && f.paymentMethodId.errors" class="invalid-feedback">
                        <div *ngIf="f.paymentMethodId.errors.required">payment method is required
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="section !== 'payment'">
                <div class="row mb-3" *ngIf="subscription">
                    <div formArrayName="products">
                        <div *ngFor="let productRow of productsForm.controls; let i=index" class="row mb-3">
                            <ng-container [formGroupName]="i">
                                <label class="col-sm-3">Products</label>
                                <div class="col-sm-4" style="margin: auto;">
                                    <div style="display: inline ;">{{subscription.products[i].name}}</div>
                                </div>
                                <div class="col-sm-4">
                                    <ng-select *ngIf="subscription.products[i].adSpendOptions.length"
                                        formControlName="adSpendAmount" [items]="subscription.products[i].adSpendOptions">
                                    </ng-select>
                                </div>
                                <div class="col-sm-1">
                                    <span *ngIf="userRoles.includes(ROLES.Admin.Code) || userRoles.includes(ROLES.ShopManager.Code)" class="input-group-text" style="cursor: pointer;"
                                        title="Click to remove product." (click)="deleteProduct(subscription.products[i].id)">
                                        <i class="fas fa-times"></i>
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row pt-2 pb-2" *ngIf="userRoles.includes(ROLES.Admin.Code) || userRoles.includes(ROLES.ShopManager.Code)">
                    <div class="col-4">
                        <button type="button" class="btn btn-sm btn-dark mt-2" (click)="addProduct()">
                            Add Product</button>
                    </div>
                </div>
                <div *ngIf="isAddProductMode" class="row mb-3 pt-2">
                    <form [formGroup]="addProductForm">
                        <div class="col-sm-5">
                            <ng-select
                                placeholder="" [items]="filteredProds" bindLabel="name" bindValue="id"
                                formControlName="assignedProductId">
                            </ng-select>
                        </div>
                    </form>
                </div>
                <div *ngIf="isAddProductMode && (userRoles.includes(ROLES.Admin.Code) || userRoles.includes(ROLES.ShopManager.Code))" class="row mb-3 pb-2">
                    <div class="col-sm-1">
                        <button [disabled]="isProductNotSelected()" type="button" class="btn btn-sm btn-dark mt-2" (click)="addProductToSub()">
                            Add</button>
                    </div>
                    <div class="col-sm-1">
                        <button type="button" class="btn btn-sm btn-dark mt-2" (click)="CancelAddMode()">
                            Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="cursor: move; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-between;" cdkDragHandle>
            <button type="button" class="btn btn-orange" (click)="activeModal.close('Close click')">Cancel</button>
            <button type="submit" class="blue-button">Save</button>
        </div>
    </form>
</div>
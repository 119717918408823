import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'underscore'
import Swal from 'sweetalert2';
import { SpecialDataTableSortableDirective, SortEvent } from './special-datatable-sortable.directive';
import { P_CampaignStageTitle } from '../../shared.pipe';
import { ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppService } from '../../../store/app.service';
import { ExportToCsv } from 'export-to-csv';
import * as lodash from 'lodash'
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { LoadDataFromServer, sortDirection } from '../datatable/datatable.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-special-datatable',
  templateUrl: './special-datatable.component.html',
  styleUrls: ['./special-datatable.component.scss'],
  providers: [DecimalPipe, P_CampaignStageTitle]
})

/**
 * Datatable Component
 */

export class SpecialDatatableComponent implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  PAGE_NAMES = PAGE_NAMES;
  @ViewChildren(SpecialDataTableSortableDirective)
  headers!: QueryList<SpecialDataTableSortableDirective>;

  dtForm: FormGroup;
  collectionSize: number = 0;

  activePage = '';
  //activeUser = {} as IUser;
  @Input() isAdmin: boolean = false;
  @Input() iCanRead: boolean = false;
  @Input() iCanWrite: boolean = false;
  @Input() iCanDelete: boolean = false;
  @Input() iShowDropdownAdd: boolean = true;
  pageNumber = 1;
  @Input() iActiveUserId: string | undefined = '';

  @Input() iHideHeaderActions: boolean = false;
  @Input() iShowExport: boolean = true;
  @Input() iShowHeader: boolean = true;
  @Input() iShowSelect: boolean = true;
  @Input() iShowMerge: boolean = false;
  @Input() iShowExternalAction: boolean = false;
  @Input() iShowDropDownDelete: boolean = true;

  @Input() iShowActions: boolean = true;
  @Input() iShowViewAction: boolean = false;
  @Input() iShowEditAction: boolean = true;
  @Input() iShowDeleteAction: boolean = true;
  @Input() iShowDeleteActionFn?: Function = undefined;
  @Input() iShowSendEmail: boolean = false;
  @Input() iShowMakeCopyAction: boolean = false;
  @Input() iShowDateRange: boolean = false;

  @Input() iShowSearch: boolean = true;
  @Input() iPageSize = 50;

  @Input() iUserRoles: Array<number> = [];
  @Input() iSortDirection = 'asc';
  @Input() iSortColumn = 'name';
  @Input() iSortColumnProps = {} as any;
  @Input() iIsServerLoading = false;
  @Input() iIsCustomDataExport = false;
  @Input() iIsExportFilter = false;
  @Input() iDateField = '';
  
  @Input() iColumns: Array<any> = [];
  @Input() set iData$(data: Array<any>) {
    this.rawData = data;
    this.prepareData();
  }
  @Input() set iTotalCount(totalCount: number) {
    this.totalCount = totalCount;
    this.prepareData();
  }
  @Input() iRowLinkHandler?: Function = undefined;

  @Input() iShowCustomAction?: Function = undefined;
  @Input() iCustomActionLabel: string = '';
  @Input() iCustomActionHandler?: Function = undefined;



  @Output() oEditRow = new EventEmitter<any>();
  @Output() oViewRow = new EventEmitter<any>();
  @Output() oShowExternal = new EventEmitter<any>();
  @Output() oAddNew = new EventEmitter<any>();
  @Output() oDeleteRows = new EventEmitter<any>();
  @Output() oArchiveRows = new EventEmitter<any>();
  @Output() oUnArchiveRows = new EventEmitter<any>();
  @Output() oRowClick = new EventEmitter<any>();
  @Output() oCBClick = new EventEmitter<any>();
  @Output() oMergeRecords = new EventEmitter<any>();
  @Output() oLoadDataFromServer = new EventEmitter<any>();
  @Output() oSendEmail = new EventEmitter<any>();
  @Output() oOnCopyClicked = new EventEmitter<any>();
  @Output() oCustomDataExport = new EventEmitter<any>();
  
  rawData: Array<any> = new Array<any>();
  viewData: Array<any> = new Array<any>();
  totalCount: number = 0;
  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    private sanitizer: DomSanitizer,
  ) {

    this.dtForm = this.formBuilder.group({
      pageSize: [this.iPageSize],
      activePage: this.pageNumber,
      searchQuery: '',
      startDate: [null],
      endDate: [null],
    });

    this.dtForm.get("searchQuery")
      ?.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(text => {
        if (!this.iIsServerLoading) {
          this.prepareData();
        }
      });

      this.dtForm.get("startDate")
      ?.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(text => {
        if (!this.iIsServerLoading) {
          this.prepareData();
        }
        else{
          this.loadData();
        }
      });

      this.dtForm.get("endDate")
      ?.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(text => {
        if(this.f?.endDate?.value){
          this.f?.endDate?.value.setDate(this.f?.endDate?.value.getDate() + 1);
        }
        if (!this.iIsServerLoading) {
          this.prepareData();
        }
        else{
          this.loadData();
        }
      });

    this.appService.getActivePage$().subscribe(p => {
      this.activePage = p;
    });
  }

  clearDates(){
    this.dtForm.get("startDate")?.setValue(null);
    this.dtForm.get("endDate")?.setValue(null);
  }

  get f() { return this.dtForm.controls; }
  ngOnInit(): void {
    this.loadData();
  }

  onPageSizeChange(evt: any) {
    this.loadData();
    this.prepareData();
  }

  onPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.loadData();
    this.prepareData();
  }

  /**
  * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
  *
  * @param rows array of data to be converted to CSV.
  * @param fileName filename to save as.
  * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
  **/
  exportToCsv() {
    if (this.iIsCustomDataExport === true) {
      if(this.iIsExportFilter == false){
        this.oCustomDataExport.emit();
      }
      else{
        const event: LoadDataFromServer = {
          pageSize: this.f.pageSize.value,
          pageNumber: this.pageNumber,
          sortColumn: this.iSortColumn,
          sortDirection: this.iSortDirection === 'asc' ? sortDirection.asc : sortDirection.desc,
          search: this.f?.searchQuery?.value,
          startDate: this.f?.startDate?.value,
          endDate: this.f?.endDate?.value
        }
        this.oCustomDataExport.emit(event);
      }
      return;
    }
    let title = this.appService.getPageTitle$().getValue();

    var filtered: Array<any> = [];
    if (this.checkedItemsCount >= 1) {
      /* select checked data */
      for (var i = 0; i < this.viewData.length; i++) {
        if (this.viewData[i].isSelected)
          filtered.push(this.viewData[i]);
      }
      /* end select checked data */
    }
    else {
      /* filter data */
      var query = this.f?.searchQuery?.value;
      if (query != null) {
        if (query === '') {
          filtered = this.rawData
        }
        else {
          filtered = this.search(query, null, null);
        }
      }
      /* end filter data */
    }

    /* prepare data, remove null amd undefined values */
    var exportData: Array<any> = [];
    filtered
      .forEach((row: any) => {
        let obj: any = {};
        this.iColumns
          .forEach((col: any) => {
            let value = this.extractFieldValue(row, col.field, col.pipe);
            if (value && value !== null && value !== undefined) {
              obj[col.title] = value;
            }
            else {
              obj[col.title] = '';
            }
          });
        exportData.push(obj);
      });
    /* end prepare data, remove null amd undefined values */

    /* export prepared data to csv */
    const options = {
      filename: title,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: title,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(exportData);
    /* end export prepared data to csv */
  }

  filterData(array: Array<any>) {
    var query = this.f?.searchQuery?.value;
    var fromDate = this.f?.startDate?.value;
    var toDate = this.f?.endDate?.value;
    var dataset = array;
    if ((query != null && query !== '') || fromDate != null || toDate !=null) {
      dataset = this.search(query, fromDate, toDate);
    }
    else {
      dataset = this.rawData
    }
    return dataset;
  }

  paginate(array: Array<any>, pageSize: number, pageNumber: number) {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }

  onCbHeadChange(evt: any) {
    for (var i = 0; i < this.viewData.length; i++) {
      this.viewData[i].isSelected = evt.target.checked;
    }
  }

  editRow(evt: any) {
    this.oEditRow.emit(evt);
  }

  onSendEmail(evt: any) {
    this.oSendEmail.emit(evt);
  }

  viewRow(evt: any) {
    this.oViewRow.emit(evt);
  }

  showExternal(evt: any) {
    this.oShowExternal.emit(evt);
  }

  addNew() {
    this.oAddNew.emit();
  }

  get checkedItemsCount() { return this.getCheckedItemsIds().length; }
  mergeRecords() {
    const itemsIds = this.getCheckedItemsIds();

    if (itemsIds.length <= 1)
      return

    this.oMergeRecords.emit(itemsIds);
  }

  deleteRows() {
    const itemsIds = this.getCheckedItemsIds();
    if (itemsIds.length === 0)
      return

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.oDeleteRows.emit(itemsIds);
      }
    })
  }
  onDeleteRow(evt: any, data: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.oDeleteRows.emit([data.id]);
        evt.stopPropagation();
      }
    })

  }

  onArchiveRow(evt: any, data: any){
    Swal.fire({
      title: 'Are you sure?',
      text: "User will be Archived and not able to use the system!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, archive it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.oArchiveRows.emit([data.id]);
        evt.stopPropagation();
      }
    })
  }

  onUnArchiveRow(evt: any, data: any){
    Swal.fire({
      title: 'Are you sure?',
      text: "User will be UnArchived and able to use the system again!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, unarchive it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.oUnArchiveRows.emit([data.id]);
        evt.stopPropagation();
      }
    })
  }

  getCheckedItemsIds() {
    const checkedList = new Array<string>();
    for (var i = 0; i < this.viewData.length; i++) {
      if (this.viewData[i].isSelected)
        checkedList.push(this.viewData[i].id);
    }
    return checkedList;
  }

  onSort({ column, direction }: SortEvent, colProps: any) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.iSortColumn = column;
    this.iSortColumnProps = colProps;
    this.iSortDirection = direction;
    this.loadData();
    this.prepareData();
  }

  loadData() {
    if (this.iIsServerLoading) {
      const event: LoadDataFromServer = {
        pageSize: this.f.pageSize.value,
        pageNumber: this.pageNumber,
        sortColumn: this.iSortColumn,
        sortDirection: this.iSortDirection === 'asc' ? sortDirection.asc : sortDirection.desc,
        search: this.f?.searchQuery?.value,
        startDate: this.f?.startDate?.value,
        endDate: this.f?.endDate?.value
      }
      this.oLoadDataFromServer.emit(event);
    }
  }

  prepareData() {
    if (this.iIsServerLoading) {
      this.viewData = this.rawData;
      this.collectionSize = this.totalCount;
    }
    else {
      var viewData = this.rawData;
      viewData = this.filterData(viewData);
      viewData = [...this.sort(viewData)];
      this.collectionSize = viewData.length;
      viewData = [...this.paginate(viewData, this.f.pageSize.value, this.pageNumber)]
      this.viewData = viewData;
    }
  }

  sort(tables: any[],): any[] {
    const sortColumns = this.iSortColumn.split(',');
    const direction = this.iSortDirection;
    if (direction === '' || sortColumns.length === 0 || tables.length === 0) return tables;
    const pipes: any[] = [];

    const pipeArgs: any[] = [];
    for (let index = 0; index < sortColumns.length; index++) {
      const sortColumn = sortColumns[index];
      const column = this.getColumnByField(sortColumn);
      if (column === undefined) {
        return tables;
      }
      if (column.pipe !== undefined) {
        const pipe = this.appService?.getPipeByName(column.pipe);
        pipes.push(pipe);
        pipeArgs.push(column.pipeArgs);
      }
      else {
        pipes.push(null);
        pipeArgs.push(null);
      }
    }
    return [...tables].sort((a, b) => {
      let valueA: string[] = [];
      let valueB: any[] = [];
      for (var i = 0; i < sortColumns.length; i++) {
        if (pipes[i] !== null) {
          valueA.push(pipes[i].transform(a[sortColumns[i]], ...pipeArgs[i]));
          valueB.push(pipes[i].transform(b[sortColumns[i]], ...pipeArgs[i]));
        }
        else {
          valueA.push(a[sortColumns[i]]);
          valueB.push(b[sortColumns[i]]);
        }
      }
      const res = this.compare(valueA, valueB);
      return direction === 'asc' ? res : -res;
    });

  }

  compare = (a: any[], b: any[]) => {
    if (a.length !== b.length) return 0;
    for (var i = 0; i < a.length; i++) {
      let valueA = a[i];
      let valueB = b[i];
      if (this.isString(valueA)) valueA = valueA.toLowerCase();
      if (this.isString(valueB)) valueB = valueB.toLowerCase();
      if (valueA !== valueB) {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      }
    }
    return 0;
  }



  search(query: string, fromDate: any, toDate: any) {
    
    let r = new Array<any>();
    for (var ele of this.rawData) {
      for (let col of this.iColumns.filter(x => (x.searchable && x.searchable === true))) {
        let value = ele[col.field];

        if (value == null || value == undefined)
          continue;

        if (col.pipe !== undefined) {
          let pipe = this.appService?.getPipeByName(col.pipe);
          value = pipe?.transform(ele[col.field], col.pipeArgs);

          if (value == null || value == undefined)
            continue;
        }

        if (value.toString().toLowerCase().indexOf(query.toLowerCase()) > -1) {
          if(fromDate != null || toDate != null){
            var fdate;
            if(typeof new Date(value).getMonth === 'function'){
              fdate = new Date(value);
            }
            else{
              const [month, day, year] = ele[this.iDateField].split('/');
              fdate = new Date(+year, +month - 1, +day);
            }
            
            if(fromDate != null && toDate != null){
              if(fdate >= fromDate && fdate <= toDate){
                r.push(ele);
                break;
              }
            }
            else if(fromDate != null){
              if(fdate >= fromDate){
                r.push(ele);
                break;
              }
            }
            else{
              if(fdate <= toDate){
                r.push(ele);
                break;
              }
            }
          }
          else{
            r.push(ele);
            break;
          }
        }
      }
    }
    return r;
  }

  extractFieldValue(row: any, colName: string, pipe: string, pipeArgs: any[] = []) {
    let value = lodash.get(row, colName);
    if (pipe) {
      //TODO:// use injector or something else to properly handle dynamic pipes
      let p = this.appService?.getPipeByName(pipe);
      value = p?.transform(value, ...pipeArgs);
    }
    return value;
  }

  getAnchorLink(type: string, param: any) {
    switch (type) {
      case 'client':
        return '/pages/client-details/' + param;
      case 'campaign':
        return '/pages/campaign-details/' + param;
      case 'subscription':
        return '/pages/subscriptions/' + param;
      default:
        return '';

    }
  }

  onCbChange(evt: any, data: any) {
    this.oCBClick.emit({ evt, data })
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  doServerSearch(event: any) {
    event.stopPropagation();
    event.preventDefault();
    if (!this.iIsServerLoading) return;
    this.loadData();
  }

  SafeHtml(str: string) {
    return this.sanitizer.bypassSecurityTrustHtml(str);
  }
  SafeUrl(str: string) {
    return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + str);
  }

  showCustomAction(row: any) {
    if (this.iShowCustomAction === undefined) return false;
    return this.iShowCustomAction.call(null, row);
  }

  showDeleteAction(row: any) {
    if (this.iShowDeleteActionFn === undefined) return true;
    return this.iShowDeleteActionFn.call(null, row);
  }

  executeCustomAction(row: any) {
    if (this.iCustomActionHandler === undefined) return;
    this.iCustomActionHandler.call(null, row);
  }

  getColumnByField(fieldName: string) {
    return this.iColumns.find(x => x.field === fieldName);
  }

  getRowLink(row: any) {
    if (this.iRowLinkHandler === undefined) return { routerLink: null, queryParams: null };
    const link = this.iRowLinkHandler.call(null, row);
    const linkParts = link.split('?');
    const linkObject: any = { routerLink: linkParts[0], queryParams: {} }
    if (linkParts.length > 1) {
      const queryParams = linkParts[1].split('&');
      for (var i = 0; i < queryParams.length; i++) {
        linkObject.queryParams[queryParams[i].split('=')[0]] = queryParams[i].split('=')[1];
      }
    }
    return linkObject;
  }

  isString = (x: any) => Object.prototype.toString.call(x) === "[object String]";
}
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { PAGE_NAMES, ROLES, ROLES_TYPE } from '../../../core/helpers/constants';
import { createEmptyGuid, createGuid, getRolesForPage, hasAdminRole, matchingPasswords } from '../../../core/helpers/utility';
import { IRole, IUser } from '../../../core/models/auth.models';
import { IClient } from '../../../core/models/clients.models';
import { UsersApiService } from '../../../pages/users/users.apiservice';
import { DataFeedService } from '../../api/datafeed.service';
import * as _ from 'underscore'
import { AppService } from '../../../store/app.service';
import { ICompany } from '../../../core/models/datafeed.models';
import Swal from 'sweetalert2';
import { ICampaign } from '../../../core/models/campaigns.model';
import { CampaignsApiService } from '../../../pages/campaigns/campaigns.apiservice';
import { AccountService } from '../../../account/account.service';
import { ToastService } from '../../../core/helpers/toast.service';

@Component({
  selector: 'modal-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserModal implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  ROLES_TYPE = ROLES_TYPE;
  ROLES = ROLES;
  showTooltip = new Array<boolean>();
  @Output() oSaveClicked = new EventEmitter<any>();

  rawRoles: Array<IRole> = new Array<IRole>();
  rolesFiltered: Array<BehaviorSubject<Array<IRole>>> = new Array<BehaviorSubject<Array<IRole>>>();
  allCompanies: Array<ICompany> = new Array<ICompany>();
  allClients: Array<IClient> = new Array<IClient>();
  allCampaigns: Array<ICampaign> = new Array<ICampaign>();
  clientsFiltered: Array<Array<IClient>> = new Array<Array<IClient>>();
  campaignsFiltered: Array<Array<ICampaign>> = new Array<Array<ICampaign>>();

  addUserForm!: FormGroup;
  submitted = false;

  rolesOptions: Array<any> = [];

  myRoles: Array<number> = new Array<number>();
  isAdmin: boolean = false;
  activeUser: IUser = {} as IUser;
  assignedRoleCodes: Array<number> = [];
  iCanCreate: Array<number> = [];
  subs = new Map<string | undefined, any>();
  canChangeRoles = false;
  @Input() set iId(value: string) {
    this.id = value;
    if (value !== null) {
      this.f.operation.setValue('edit');
      this.f.password.setValidators([]);
      this.f.confirmPassword.setValidators([]);
      this.userApiService.getUserDetails(value)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.addUserForm.patchValue(response.data);
          for (var role of response.data.roles) {
            this.addRole(role.id, role.roleId, role.companyId, role.clientId, role.campaignId, role.code)
          }
          this.syncRolesUI();
        });
    } else {
      this.f.password.setValidators([Validators.required]);
      this.f.confirmPassword.setValidators([Validators.required]);
      this.f.operation.setValue('create');
    }
  }
  id: string = "";

  constructor(
    private toastService: ToastService,
    private dataFeedService: DataFeedService,
    private userApiService: UsersApiService,
    public activeModal: NgbActiveModal,
    private campaignApiService: CampaignsApiService,
    private appService: AppService,
    private accountService: AccountService,
    private formBuilder: FormBuilder) {
    this.addUserForm = this.formBuilder.group({
      id: [null],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: [''],
      type: [null],
      companyId: [''],
      email: ['', [Validators.required, Validators.email]],
      sendEmail: [false],
      operation: 'edit',
      roles: this.formBuilder.array([])
    }, { validator: matchingPasswords('password', 'confirmPassword') });

    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.activeUser = u;
        this.myRoles = u.roles.map(x => x.code);
        this.isAdmin = hasAdminRole(u.roles);
        this.addUserForm.patchValue({ type: u.type });
        var r = getRolesForPage(u.roles, PAGE_NAMES.Users);
        this.canChangeRoles = this.isAdmin || u.iCanCreate.length > 0;
      });
  }
  get f() { return this.addUserForm.controls; }

  public get roles(): FormArray {
    return this.addUserForm.get('roles') as FormArray
  }
  addRole(id?: string, roleId?: string, companyId?: string, clientId?: string, campaignId?: string, code?: number) {
    id = id == undefined ? createGuid() : id;
    this.rolesFiltered.push(new BehaviorSubject<Array<IRole>>([]));
    let index = this.rolesFiltered.length - 1;
    const sub = combineLatest([
      this.appService.getAllClientsFeed$(),
      this.appService.getRoleRestrictions$(),
      this.appService.getAllRoles$(),
      this.campaignApiService.getCampaignsNamesByClient(clientId ?? createEmptyGuid())
    ]).pipe(
      takeUntil(this.destroyed$),
      filter(x => x[0].length > 0 && x[1].length > 0 && x[2].length > 0),
    ).subscribe((r: any) => {
      let response: any = r[0];
      let roleRestrictions: any = r[1];
      let allRoles = this.rawRoles = [...r[2]];

      this.assignedRoleCodes = allRoles.filter(y => this.roles.value.map((x: any) => x.roleId).includes(y.id)).map(x => x.code);
      this.allClients = [...response].map(i => { i.fullName = i.firstName + ' ' + i.lastName; return i; });
      if (companyId)
        this.clientsFiltered.push([...this.allClients.filter(x => x.brandId === companyId).map((i: any) => { i.fullName = i.firstName + ' ' + i.lastName; return i; })]);
      else
        this.clientsFiltered.push([...this.allClients.map((i: any) => { i.fullName = i.firstName + ' ' + i.lastName; return i; })]);

      // if (campaignId) {
      this.campaignsFiltered.push(r[3].data as Array<ICampaign>)
      // }

      this.roles.push(this.newRole(id, roleId, companyId, clientId, campaignId, code));
      if (this.assignedRoleCodes.length === 0 || this.assignedRoleCodes.every(x => x === ROLES.Admin.Code))
        this.rolesFiltered[index].next(allRoles!)
      else {
        let fr: Array<IRole> = [];
        for (let role of allRoles) {
          for (let aes of this.assignedRoleCodes) {
            let ress = roleRestrictions.find((x: any) => x.userRoleCode == aes);
            if (ress && ress.iCanBe !== null)
              if (ress.iCanBe.includes(role.code)) {
                fr.push(role);
              }
            if (role.code == aes && role.code !== ROLES.Admin.Code && !fr.some(x => x.code === role.code)) {
              fr.push(role);
            }
          }
        }
        this.rolesFiltered[index].next(fr)
      }
      this.subs.get(id).unsubscribe();
      this.syncRolesUI();
    });
    this.subs.set(id, sub);

  }
  newRole(id?: string, roleId?: string, companyId?: string, clientId?: string, campaignId?: string, code?: number): FormGroup {
    this.rolesOptions.push({});
    return this.formBuilder.group({
      code: code,
      id: [id || createGuid()],
      roleId: [roleId || null, Validators.required],
      companyId: companyId || null,
      clientId: clientId || null,
      campaignId: campaignId || null
    })
  }
  deleteRole(i: number) {
    this.rolesOptions.splice(i, 1);
    this.roles.removeAt(i);
    this.clientsFiltered.splice(i, 1);
    this.rolesFiltered.splice(i, 1)
  }
  onRoleChange(evt: any, index: number) {
    if (index == 0)
      for (let i = 0; i < this.roles.value.length; i++) {
        if (i !== 0)
          this.deleteRole(i);
      }

    this.appService.getAllRoles$()
      .pipe(take(1))
      .subscribe((allRoles: Array<IRole>) => {
        this.rawRoles = allRoles;
        var selectedRoleId = this.roles.at(index)?.get('roleId')?.value;
        this.showTooltip[index] = [ROLES.Employee.Code, ROLES.SubscriptionEmployee.Code].includes(this.getRoleCodeByRoleId(selectedRoleId))

        var roleCode: any = allRoles.find(x => x.id == evt)?.code;
        let roleAttr: any = _.find(ROLES, function (item) {
          return item.Code == roleCode
        })
        if (roleAttr?.Company) {
          this.rolesOptions[index] = { ...this.rolesOptions[index], Company: roleAttr.Company.Required };
          //if (this.myRoles.some(r => [ROLES.MarketingManager.Code, ROLES.CompanyOwner.Code].includes(r)))
          if(roleAttr.Company.Required){
            this.roles.at(index)?.get('companyId')?.setValidators([Validators.required]);
          }
          else{
            this.roles.at(index)?.get('companyId')?.clearValidators();
            this.roles.at(index)?.get('companyId')?.updateValueAndValidity();
          }
        }
        else {
          delete this.rolesOptions[index].Company;
          this.roles.at(index)?.get('companyId')?.clearValidators();
          this.roles.at(index)?.get('companyId')?.updateValueAndValidity();
        }

        if (roleAttr?.Client) {
          this.rolesOptions[index] = { ...this.rolesOptions[index], Client: roleAttr.Client.Required };
          if(roleAttr.Client.Required){
            this.roles.at(index)?.get('clientId')?.setValidators([Validators.required]);
          }
          else{
            this.roles.at(index)?.get('clientId')?.clearValidators();
            this.roles.at(index)?.get('clientId')?.updateValueAndValidity();
          }
        }
        else {
          delete this.rolesOptions[index].Client;
          this.roles.at(index)?.get('clientId')?.clearValidators();
          this.roles.at(index)?.get('clientId')?.updateValueAndValidity();
        }

        if (roleAttr?.Campaign) {
          this.rolesOptions[index] = { ...this.rolesOptions[index], Campaign: roleAttr.Campaign.Required };
          if(roleAttr.Campaign.Required){
            this.roles.at(index)?.get('campaignId')?.setValidators([Validators.required]);
          }
          else{
            this.roles.at(index)?.get('campaignId')?.clearValidators();
            this.roles.at(index)?.get('campaignId')?.updateValueAndValidity();
          }
        }
        else {
          delete this.rolesOptions[index].Campaign;
          this.roles.at(index)?.get('campaignId')?.clearValidators();
          this.roles.at(index)?.get('campaignId')?.updateValueAndValidity();
        }
      });
  }
  onRoleCompanyChange(evt: any, index: number) {
    if (typeof this.clientsFiltered[index] !== 'undefined' && evt.id !== null) {
      this.clientsFiltered[index] = [...this.allClients.filter(x => x.brandId === evt.id).map((i: any) => { i.fullName = i.firstName + ' ' + i.lastName; return i; })]
    }
  }
  onRoleClientChange(evt: any, index: number) {
    this.campaignApiService.getCampaignsNamesByClient(evt.id)
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response.good) {
          this.campaignsFiltered[index] = response.data;
        }
      });
  }
  syncRolesUI() {
    let index = 0;
    for (var role of this.f.roles.value) {
      let roleAttr: any = _.find(ROLES, (item) => {
        let code = this.getRoleCodeByRoleId(role.roleId);
        return item.Code == code
      })
      if (roleAttr?.Company) {
        this.rolesOptions[index] = { ...this.rolesOptions[index], Company: roleAttr.Company.Required };
        //if (this.myRoles.some(r => [ROLES.MarketingManager.Code, ROLES.CompanyOwner.Code].includes(r)))
        this.roles.at(index)?.get('companyId')?.setValidators([Validators.required]);
      }
      else {
        delete this.rolesOptions[index].Company;
        this.roles.at(index)?.get('companyId')?.clearValidators();
      }

      if (roleAttr?.Client) {
        this.rolesOptions[index] = { ...this.rolesOptions[index], Client: roleAttr.Client.Required };
        // this.roles.at(index)?.get('clientId')?.setValidators([Validators.required]);
      }
      else {
        delete this.rolesOptions[index].Client;
        // this.roles.at(index)?.get('clientId')?.clearValidators();
      }

      if (roleAttr?.Campaign) {
        this.rolesOptions[index] = { ...this.rolesOptions[index], Campaign: roleAttr.Campaign.Required };
        // this.roles.at(index)?.get('campaignId')?.setValidators([Validators.required]);
      }
      else {
        delete this.rolesOptions[index].Campaign;
        // this.roles.at(index)?.get('campaignId')?.clearValidators();
      }
      index++;
    }
  }

  updateData(data: IClient) {
    this.addUserForm.patchValue(data);
  }
  getRoleCodeByRoleId(roleId: string) {
    return this.rawRoles.find(x => x.id === roleId)?.code!
  }

  ngOnInit() {
    this.appService.fetchRoleRestrictions();
    this.appService.fetchAllClientsFeed();
    this.appService.fetchAllRoles();
    this.dataFeedService.getAllCompanies()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allCompanies = [...response.data];
        }
      });
  }
  ngAfterViewInit() {
    this.assignedRoleCodes = this.roles.value.map((x: any) => x.code);
  }

  loadUser() {
    if (this.id !== '') {
      this.userApiService.getUserDetails(this.id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.addUserForm.patchValue(response.data);
          for (var role of response.data.roles) {
            this.addRole(role.id, role.roleId, role.companyId, role.clientId, role.campaignId, role.code)
          }
          this.syncRolesUI();
        });
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.f.operation.value === 'create' && this.f.roles.value.length === 0) {
      this.f.roles.setErrors({ 'required': true });
    }
    if (this.addUserForm.invalid)
      return;
    if (this.f.id.value === null)
      this.f.id.setValue(createGuid());

    if (this.f.operation.value === 'edit' && this.f.roles.value.length === 0 && this.canChangeRoles) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This user has no roles, it will be deleted',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.oSaveClicked.emit(this.addUserForm.value);
        }
      })
    }
    else {
      var request = this.addUserForm.value;
      request = this.trimObjectStrings(request);
      this.oSaveClicked.emit(request);
    }
  }

  // This function will recursively iterate through the object
  trimObjectStrings(obj: any): any {
    // Iterate over each property of the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        
        if (typeof value === 'string') {
          // Trim the string if the value is a string
          obj[key] = value.trim();
        } else if (typeof value === 'object' && value !== null) {
          // Recursively call the function if the value is an object (or array)
          this.trimObjectStrings(value);
        }
      }
    }
    return obj;
  }

  onAddUserTooltipShown(index: number) {
    var sub = this.rolesFiltered[index]
      .subscribe(roles => {
        // this.showTooltip = 
      })
    sub.unsubscribe();
  }
  sendPasswordResetEmail() {
    let email = this.f.email.value;
    this.addUserForm.markAllAsTouched();
    if (this.f.email.valid) {
      this.toastService.showStandard('Sending Email, Please wait');
      this.accountService.requestResetPassword(email)
        .pipe(takeUntil(this.destroyed$)
        )
        .subscribe(response => {
          if (response.good) {
            this.toastService.showSuccess('Email Sent');
          }
        });
    }
    else {
      this.toastService.showDanger('Invalid email address');
    }

  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}

<div fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div class="polygon-tab" [class.active]="!showCompleted" (click)="toggleTab(false)">
            <h2 style="margin-bottom: 0px !important;">TO-DO</h2>
        </div>
        <div class="polygon-tab" [class.active]="showCompleted" (click)="toggleTab(true)">
            <h2 style="margin-bottom: 0px !important;">Completed</h2>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
        <button type="button" class="gray-button">
            <label for="showMyTasksCheckbox">
                <input type="checkbox" id="showMyTasksCheckbox" [(ngModel)]="showOnlyMyTasks" (change)="onShowMyTasksChange()" value="Show Only My Tasks">
                Show Only My Tasks
            </label>
        </button>
        <label style="margin-bottom: 0px !important; padding-right: 8px; margin-left: 32px !important;">
            Show Only Tasks With Due Date:
        </label>
        <form [formGroup]="dtForm">
            <select formControlName="showTasksDue" (change)="onDueDateFilterChange()" style="border-radius: 4px;">
                <option>All</option>
                <option>Overdue</option>
                <option>Today</option>
                <option>Upcoming</option>
            </select>
        </form>
    </div>
</div>
<app-tasks-datatable
    [iSortColumn]="'title'"
    [iShowActions]="userRoles.includes(ROLES.Admin.Code)"
    (oRowClick)="editRow($event)"
    [iUserRoles]="userRoles"
    (oCBClick)="onCheckBoxChange($event)"
    [iHideHeaderActions]="true"
    [iColumns]="(showCompleted) ? completedTasksColumns : columns"
    (oDeleteRows)="deleteRows($event)"
    (oEditRow)="editRow($event)"
    [iData$]="todoTasksData"
    [iIsServerLoading]="true"
    [iTotalCount]="dataTotalCount"
    (oLoadDataFromServer)="onLoadingData($event)"
    [iShowRedStyling]="!showCompleted">
</app-tasks-datatable>

import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/http/base.http';


@Injectable()
export class PaymentMethodApiService extends HttpService {

   getAllPaymentMethods() {
      return this.get<Array<any>>('/PaymentMethod/all?');
   }

   getPaymentMethodsByClient(clientId: any) {
      return this.get<Array<any>>('/PaymentMethod/Client?clientId=' + clientId);
   }

   getPaymentMethod(id: string) {
      return this.get('/PaymentMethod?id=' + id);
   }

   addEdit(data: any) {
      return this.post('/PaymentMethod', data);
   }

   deletePaymentMethod(ids: any) {
      return this.post('/PaymentMethod/Delete', ids);
   }

   getSubscriptionsByClient(clientId: string) {
      return this.get<any>(`/Subscriptions/all?clientId=${clientId}`);
   }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PAGE_NAMES, ROLES_TYPE } from '../../../core/helpers/constants';
import { getRolesForPage } from '../../../core/helpers/utility';
import { IPermission } from '../../../core/models/auth.models';
import { ICompany } from '../../../core/models/datafeed.models';
import { DataFeedService } from '../../../shared/api/datafeed.service';
import { AppService } from '../../../store/app.service';

@Component({
  selector: 'app-reports-rollup-main',
  templateUrl: './reports-rollup-main.component.html',
  styleUrls: ['./reports-rollup-main.component.scss']
})
export class ReportsRollupMainComponent implements OnInit {
  doWhat: IPermission = {} as IPermission;

  columns = [
    { title: 'Company Name', field: 'name', sortable: true, searchable: true },
    // { title: 'Contact Name', field: 'companyOwner.firstName', sortable: true, searchable: true },
    // { title: 'Contact Email', field: 'companyOwner.email', searchable: true, sortable: true },
    // { title: 'Contact Phone', field: 'companyOwner.phone', searchable: true },
  ];
  companiesData: Array<ICompany> = [];
 
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  isAdmin: boolean = false;
  roleOptions: any = {};
  isExternal: boolean = false;

  constructor(
    private router: Router,
    private appService: AppService, 
    private dataFeedService: DataFeedService) {
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Rollup Report');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Reports);

    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.isExternal = u.type == ROLES_TYPE.External
        var r = getRolesForPage(u.roles, PAGE_NAMES.Reports);
        this.isAdmin = r.isAdmin;
        if (r.options)
          this.roleOptions = JSON.parse(r.options!);
      });

    this.getAssociatedCompanies(); 
  }

  getAssociatedCompanies() {
    this.dataFeedService.getAssociatedCompanies()
      .subscribe(res => {
        // console.log(res);
        this.companiesData = [...res.data];
      });
    ;
  } 
 
  onCompanySelected(evt: any ): void {
    console.log(evt);
    this.router.navigate(['/pages/reports/rollup-campaigns', evt.id]);
  } 
 
}
<div class="row">
  <div class="col-md-12">
    <div class="card-body">
      <div class="details_top">
        <div class="row row-gap-4 d-flex align-items-stretch">
          <div class="col-12 col-xl-6">
            <div class="card details-card">
              <h5 class="card-title">Lead Info:</h5>
              <div class="card-body">
                <p><strong>Name:</strong> {{leadData?.firstName}} {{leadData?.lastName}}</p>
                <p><strong>Email:</strong> {{leadData?.email}}</p>
                <p><strong>Phone:</strong> {{leadData?.phone}}</p>
                <p><strong>Received At:</strong> {{ formatDate(leadData.createdAt) }}</p>
                <p><strong>Referral Source:</strong> {{leadData?.referralSource}}</p>
                <p><strong>Referral ID:</strong> {{leadData?.referralId}}</p>

                <p *ngIf="leadData.isMember"><strong>Converted to a Member? </strong> <span *ngIf="leadData.isMember">Yes</span>
                  <span *ngIf="!leadData.isMember">No</span></p>
                <p *ngIf="leadData.isMember"><strong>Member Since:</strong> {{leadData.memberSince | P_AppDateFormat}}</p>
              </div>
            </div>
          </div>

          <div class="col-12 col-xl-6">
            <div class="card details-card">
              <h5 class="card-title">Campaign Info:</h5>
              <div class="card-body">
                <p><strong>Campaign:</strong> 
                  <a style="color: blue;" [routerLink]="['/pages/campaign-details', leadData?.campaignId]" class=""
                    title="View Campaign">
                    {{leadData?.campaignName}}
                  </a>
                </p>
                <p *ngIf="leadData?.crmName"><strong>CRM:</strong> {{ leadData?.crmName }}</p>
                <p *ngIf="leadData?.crmId"><strong>CRM ID:</strong> {{ leadData?.crmId }}</p>
                <p *ngIf="leadData?.pageTitle"><strong>Landing Page Title:</strong> {{leadData?.pageTitle}}</p>
                <p *ngIf="leadData?.pageUrl"><strong>Landing Page URL:</strong> {{leadData?.pageUrl}}</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="dv-custom-navs">
      <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
        <li [ngbNavItem]="1" style="clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%) !important;">
          <a ngbNavLink>
            <i-feather name="paperclip"></i-feather><label>NOTES</label>
          </a>
          <ng-template ngbNavContent>
            <app-notes [iCanDelete]="doWhat.isAdmin || (doWhat.roleOptions && doWhat.roleOptions.notes.canDelete)" [iCanWrite]="doWhat.isAdmin || (!isExternal) || doWhat.isAdmin || (doWhat.roleOptions && doWhat.roleOptions.notes.canWrite)" [iParentId]="leadData.id" [iType]="3"></app-notes>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { IPermission } from '../../../core/models/auth.models';
import { ICoupon } from '../../../core/models/coupon.models';
import { AppService } from '../../../store/app.service';
import { CouponsApiService } from '../coupon.apiservice';
@Component({
  selector: 'app-coupons',
  templateUrl: './coupons-main.component.html',
  styleUrls: ['./coupons-main.component.scss']
})
export class CouponsMainComponent implements OnInit {
  doWhat: IPermission = {} as IPermission;

  columns = [
    { title: 'Code', field: 'code', sortable: true, searchable: true },
    { title: 'Type', field: 'type', sortable: true, searchable: true, pipe: 'P_CouponTypesTitle' },
    { title: 'Amount', field: 'amount', sortable: true, searchable: true },
    { title: 'Description', field: 'description', sortable: true, searchable: true },
    { title: 'Product Names', field: 'includeProductNames', sortable: true, searchable: true },
    { title: 'Time Limit', field: 'expiryDate', searchable: true, pipe: 'P_CouponExpiry' },
  ]
  couponsData: Array<ICoupon>
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private router: Router,
    private cApiService: CouponsApiService,
    private route: ActivatedRoute,
    private appService: AppService) {
    this.couponsData = new Array<ICoupon>();
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Coupons');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Coupons);
    this.appService.getAllCoupons$()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: Array<ICoupon>) => {
        this.couponsData = [...response];
      });


  }
  ngAfterViewInit() {
    const savedSearchId = this.route.snapshot.queryParams['savedsearchid'];
    this.appService.fetchAllCoupons(savedSearchId);
  }

  editRow(evt: any) {
    this.router.navigate(['/pages/edit-coupon', evt.id]);
  }

  deleteRows(evt: any) {
    this.cApiService.deleteCoupon(evt)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.good) {
          Swal.fire('Coupons ', "Coupon(s) deleted", 'success')
        }
        this.appService.fetchAllCoupons(null);
      })
  }

  getRowLink(row: any) {
    return `/pages/edit-coupon/${row.id}`;
  }
}

<div class="row">
  <div class="col-md-12">
    <div class="card-body">
      <div class="details_top">
        <h2 style="text-align: center;">{{clientData?.firstName}} {{clientData?.lastName}}</h2>
        <h4 style="text-align: center;">{{clientData?.brand}}</h4>

        <div class="row row-gap-4 d-flex align-items-stretch" style="margin-top: 25px;">
          <div class="col-12 " [ngClass]="(isExternal) ? 'col-xl-6' : 'col-xl-6'">
            <div class="card details-card">
              <h5 class="card-title">Contact Info:</h5>
              <div class="card-body">
                <p><strong>Email:</strong> {{clientData?.email}} <a *ngIf="clientData.email" style="color: inherit;" href="mailto:{{ clientData.email }}"><i-feather name="mail" style="width: 1.3em; height: 1em; stroke-width: 1px;"></i-feather></a></p>
                <p><strong>Phone:</strong> {{clientData?.phone}}</p>
                <p><strong>Address:</strong><br *ngIf="clientData?.brandAddress" />
                  {{clientData?.brandAddress}}</p>
              </div>
            </div>
          </div>

          <div class="col-12 " [ngClass]="(isExternal) ? 'col-xl-6' : 'col-xl-6'">
            <div class="card details-card">
              <h5 class="card-title">Additional Info:</h5>
              <div class="card-body">
                <p><strong>DMS:</strong> {{ clientData?.dmsName }}</p>
                <p><strong>Sales Rep:</strong> {{ clientData?.salesRep }}</p>
                <p *ngIf="!isExternal"><strong>Owner User Record:</strong> {{clientData?.clientOwner}}</p>
                
                <p><strong>Employee Users:</strong><br *ngIf="clientData?.employees?.length !== 0" />
                  <span *ngFor="let e of clientData.employees; let last = last">
                  {{ e.firstName }} {{ e.lastName }}<ng-container *ngIf="!last">, </ng-container>
                  </span>
                </p>

              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="dv-custom-navs">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
          <li [ngbNavItem]="1" [ngStyle]="{'z-index': (active === 1) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.notes && roleOptions.notes.canRead)">
            <a ngbNavLink>
              <i-feather name="paperclip"></i-feather><label>NOTES</label>
            </a>
            <ng-template ngbNavContent>
              <app-notes [iIsAdmin]="isAdmin" [iIsExternal]="isExternal"
                [iCanDelete]="isAdmin || roleOptions.notes.canDelete"
                [iCanWrite]="isAdmin || roleOptions.notes.canWrite" [iParentId]="clientData.id" [iType]="2">
              </app-notes>
            </ng-template>
          </li>
          <li [ngbNavItem]="2" style="left: -5px;" [ngStyle]="{'z-index': (active === 2) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.campaigns && roleOptions.campaigns.canRead)" (click)="loadCampaigns()">
            <a ngbNavLink>
              <i-feather name="edit"></i-feather><label>CAMPAIGNS</label>
            </a>
            <ng-template ngbNavContent>
              <div style="background-color: white; padding: 24px 16px 8px 16px;">
                <app-campaign-datatable [iCanRead]="isAdmin || (roleOptions.campaigns && roleOptions.campaigns.canRead)"
                  [iCanWrite]="isAdmin || (roleOptions.campaigns && roleOptions.campaigns.canWrite)" [iShowSelect]="false"
                  [iCanDelete]="isAdmin || (roleOptions.campaigns && roleOptions.campaigns.canDelete)"
                  (oRowClick)="onCampaignsRowClick($event)" [iColumns]="columnsCampaign" [iShowActions]="false"
                  [iData$]="campaignsData" [iRowLinkHandler]="getCampaignRowLink">
                </app-campaign-datatable>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" style="left: -10px;" [ngStyle]="{'z-index': (active === 3) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.tasks && roleOptions.tasks.canRead)" (click)="loadTasks()">
            <a ngbNavLink>
              <i-feather name="list"></i-feather><label>TASKS</label>
            </a>
            <ng-template ngbNavContent>
              <div style="background-color: white;">
                <div style="display: flex; padding: 8px 16px 16px 16px; justify-content: flex-end; align-items: center;">
                  <button class="blue-button" type="button" (click)="onAddTask()">ADD TASK</button>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div (click)="changeShowTasks()" style="padding-left: 16px; padding-right: 16px;">
                      <h2 [style.color]="showTasks ? '#495057' : '#EBE9EB'" [style.border-bottom]="showTasks ? '2px solid rgb(73, 80, 87)' : '2px solid #EBE9EB'" style="padding: 0px 16px 8px 16px; margin-bottom: 0px !important; cursor: pointer;">TO-DO</h2>
                    </div>
                    <div (click)="changeShowCompletedTasks()" style="padding-left: 16px; padding-right: 16px;">
                      <h2 [style.color]="showCompletedTasks ? '#495057' : '#EBE9EB'" [style.border-bottom]="showCompletedTasks ? '2px solid rgb(73, 80, 87)' : '2px solid #EBE9EB'" style="padding: 0px 16px 8px 16px; margin-bottom: 0px !important; cursor: pointer;">COMPLETED</h2>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center" style="padding-right: 16px;">
                    <button type="button" class="gray-button">
                      <label for="showMyTasksCheckbox">
                          <input type="checkbox" id="showMyTasksCheckbox" [(ngModel)]="showOnlyMyTasks" (change)="onShowMyTasksChange()" value="Show Only My Tasks">
                          Show Only My Tasks
                      </label>
                    </button>
                  </div>
                </div>
                <app-tasks-datatable *ngIf="showTasks" [iCanRead]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canRead)"
                  [iCanWrite]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canWrite)"
                  [iCanDelete]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canDelete)"
                  (oRowClick)="onTaskRowClick($event)" [iSortColumn]="'title'" [iColumns]="columnsTasks" [iShowActions]="false"
                  [iData$]="tasksData">
                </app-tasks-datatable>
                <app-tasks-datatable *ngIf="showCompletedTasks" [iCanRead]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canRead)"
                  [iCanWrite]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canWrite)"
                  [iCanDelete]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canDelete)"
                  (oRowClick)="onTaskRowClick($event)" iSortColumn="completedOn" [iColumns]="compltedTasksColumns" [iShowActions]="false"
                  [iData$]="completedTasksData" [isCompleted]="true">
                </app-tasks-datatable>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="4" style="left: -15px;" [ngStyle]="{'z-index': (active === 4) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.tags && roleOptions.tags.canRead)">
            <a ngbNavLink>
              <i-feather name="tag"></i-feather><label>TAGS</label>
            </a>
            <ng-template ngbNavContent>
              <app-tags-tab [iCanWrite]="isAdmin || (roleOptions.tags && roleOptions.tags.canWrite)"
                [iParentId]="clientData.id" [iType]="2"></app-tags-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="5" style="left: -20px;" [ngStyle]="{'z-index': (active === 5) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.subscriptions && roleOptions.subscriptions.canRead)">
            <a ngbNavLink>
              <i-feather name="check"></i-feather><label>SUBSCRIPTIONS</label>
            </a>
            <ng-template ngbNavContent>
              <app-subscription-shared [iClientId]="clientData.id">
              </app-subscription-shared>
            </ng-template>
          </li>
          <li style="left: -25px;" (click)="loadOrders();" [ngStyle]="{'z-index': (active === 6) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.dashboard && roleOptions.dashboard.canRead)" [ngbNavItem]="6">
            <a ngbNavLink>
              <i-feather name="dollar-sign"></i-feather><label>ORDERS</label>
            </a>
            <ng-template ngbNavContent>
              <div style="background-color: white; padding: 24px 16px 8px 16px;">
                <app-campaign-datatable
                iSortColumn="numericId"
                iSortDirection="desc"
                [iCanRead]="doWhat.canRead"
                [iColumns]="orderColumns"
                [iData$]="checkingOrders"
                [iHideHeaderActions]="false"
                [iShowActions]="true"
                [iShowEditAction]="false"
                [iShowExport]="false"
                [iShowSelect]="false"
                [iShowViewAction]="true"
                [iTotalCount]="checkingOrders.length"
                (oRowClick)="onClick($event)">
              </app-campaign-datatable>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { ITask } from '../../core/models/tasks.models';
import { HttpService } from '../../shared/http/base.http';

@Injectable()
export class TasksApiService extends HttpService {

   getAllTasks(request: any) {
      return this.post<any>('/tasks/getAllTasks', request);
   }

   getTasksByClient(id: string) {
      return this.get<Array<ITask>>('/tasks/getTasksByClient?id=' + id)
   }

   getTasksByCampaign(id: string) {
      return this.get<Array<ITask>>('/tasks/getTasksByCampaign?id=' + id)
   }
   
   getTaskDetails(id: string) {
      return this.get<Array<ITask>>(`/tasks/getTaskDetails?id=${id}`)
   }

   saveTask(data: ITask) {
      return this.post('/tasks/saveTask', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }

   deleteTask(id: string) {
      return this.delete(`/tasks/deleteTask/${id}`);
   }

   markTask(data: any) {
      return this.post('/tasks/markTask', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { IPermission } from '../../../core/models/auth.models';
import { IClient } from '../../../core/models/clients.models';
import { LoadDataFromServer } from '../../../shared/components/datatable/datatable.model';
import { AppService } from '../../../store/app.service';
import { ClientsApiService } from '../clients.apiservice';
import { ClientsService } from '../clients.service';
import { MergeClientsModal } from '../../../shared/modals/merge-clients/merge-clients.component';

@Component({
  selector: 'app-prospects',
  templateUrl: './prospects-main.component.html',
  styleUrls: ['./prospects-main.component.scss']
})
export class ProspectsMainComponent implements OnInit {
  doWhat: IPermission = {} as IPermission;
  columns = [
    { title: 'First Name', field: 'firstName', sortable: true, searchable: true },
    { title: 'Last Name', field: 'lastName', sortable: true, searchable: true },
    { title: 'Company', field: 'brand', sortable: true, searchable: true },
    { title: 'DMS', field: 'dmsName', sortable: true, searchable: true },
    { title: 'Sales Rep', field: 'salesRep', sortable: true, searchable: true },
    { title: 'Phone', field: 'phone', sortable: true, searchable: true },
    { title: 'Email', field: 'email', sortable: true, searchable: true },
  ]
  clientsData: Array<IClient>
  savedSearchId: any;
  private loadEvent!: LoadDataFromServer;
  dataTotalCount: number = 0;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private cService: ClientsService,
    private clientService: ClientsApiService,
    private appService: AppService) {
    this.clientsData = new Array<IClient>();
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Prospects');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Prospects);
    this.appService.getAllProspectClients$()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        this.clientsData = [...response.data];
        this.dataTotalCount = response.totalCount;
      });
    this.savedSearchId = this.route.snapshot.queryParams['savedsearchid'];
  }

  onLoadingData(event: LoadDataFromServer) {
    this.loadEvent = event;
    this.appService.fetchAllProspectClients({
      SavedSearchId: this.savedSearchId,
      ...this.loadEvent
    });
  }

  editRow(evt: any) {
    this.cService.openEditClientModal(evt,{
      SavedSearchId: this.savedSearchId,
      ...this.loadEvent
    });
  }

  deleteRows(evt: any) {
    this.clientService.deleteClient(evt)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.appService.fetchAllProspectClients({
          SavedSearchId: this.savedSearchId,
          ...this.loadEvent
        });
      })
  }

  getRowLink(row: any) {
    return `/pages/prospect-details/${row.id}`;
  }

  ngAfterViewInit() { }

  handleMergeRecordsClicked(evt: string[]) {
    let clientsToMerge = this.clientsData.filter(f => evt.includes(f.id));
    let size = clientsToMerge.length == 2 ? 'lg' : 'xl';

    const dialogRef = this.modalService.open(MergeClientsModal, { size: size });

    dialogRef.componentInstance.clientsToMerge = clientsToMerge;
    const dialogSubmitSubscription = dialogRef.componentInstance.oMergeComplete.subscribe((r: any) => {
      this.appService.fetchAllProspectClients({
        SavedSearchId: this.savedSearchId,
        ...this.loadEvent
      });
      dialogSubmitSubscription.unsubscribe();
    })
  }

  async export() {
    this.clientService.exportProspectClients({
      SavedSearchId: this.savedSearchId,
      ...this.loadEvent
    }).then((x: any) => {
      var newBlob = new Blob([x], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = "prospect-clients.xlsx";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }
}

<div class="card" style="background-color: transparent !important;">
   <div class="header">
      <p class="seperator"></p>
      <button type="button" class="gray-button" (click)="hideInactive = !hideInactive; onHideInactiveChange()">
         <label for="hideInactive" (click)="$event.stopPropagation()">
            <input type="checkbox" id="hideInactive" [(ngModel)]="hideInactive" (change)="onHideInactiveChange()" value="Hide Inactive Campaigns">
            Hide Inactive Campaigns
         </label>
      </button>
      <button type="button" class="gray-button" *ngIf="doWhat.isAdmin" style="margin-left: 15px;" (click)="showDeleted = !showDeleted; onShowDeletedChange()">
         <label for="showDeleted" (click)="$event.stopPropagation()">
            <input type="checkbox" id="showDeleted" [(ngModel)]="showDeleted" (change)="onShowDeletedChange()" value="Show Deleted Campaigns">
            Show Deleted Campaigns
         </label>
      </button>
   </div>
   <app-campaign-datatable [iShowDropdownAdd]="true" [iColumns]="columns" [iCanRead]="doWhat.canRead" [iCanWrite]="doWhat.canWrite"
      [iCanDelete]="doWhat.canDelete" [iRowLinkHandler]="getRowLink" (oDeleteRows)="deleteRows($event)" [iShowMerge]="doWhat.isAdmin || userRoles.includes(ROLES.TechServices.Code)"
      (oMergeRecords)="handleMergeRecordsClicked($event)" (oEditRow)="editRow($event)" [iData$]="campaignsData" [iIsServerLoading]="true" [iTotalCount]="dataTotalCount" 
      (oLoadDataFromServer)="onLoadingData($event)" (oCustomDataExport)="export()" [iIsCustomDataExport]="true"></app-campaign-datatable>
</div>
import * as _ from 'underscore';
import * as _flatted from 'flatted';
import { ReplaySubject } from 'rxjs';
import { Location } from '@angular/common';
import { filter, takeUntil } from 'rxjs/operators';
import { ShopApiService } from '../shop.apiservice';
import { WizardComponent } from 'angular-archwizard';
import {COMMA, ENTER, N} from '@angular/cdk/keycodes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../../store/app.service';
import { IUser } from '../../../core/models/auth.models';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {MatChipInputEvent} from '@angular/material/chips';
import { IClient } from '../../../core/models/clients.models';
import { ICompany,} from '../../../core/models/datafeed.models';
import { getRolesForPage } from '../../../core/helpers/utility';
import { ICampaign } from '../../../core/models/campaigns.model';
import { ClientsApiService } from '../../clients/clients.apiservice';
import { UtilityService } from '../../../core/helpers/utility.service';
import { DataFeedService } from '../../../shared/api/datafeed.service';
import { CampaignsApiService } from '../../campaigns/campaigns.apiservice';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductInputComponent } from '../product-input/product-input.component';
import { PaymentMethodApiService } from '../../my-account/payment-method/payment-method.apiservice';
import { AddPaymentMethodModal } from '../../../shared/modals/add-payment-method/add-payment-method.component';
import { CART_STEPS, PAGE_NAMES, PRODUCT_FAMILY, PRODUCT_TYPES, TRX_STATUS } from '../../../core/helpers/constants';
import { Component, ElementRef, HostListener, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { CartStepData, CouponResponse, ICart, ICartData, ICartProductDetails, IProductFinalized, IProductMain, IProductMedia } from '../../../core/models/products.models';

export interface Coupon {
  name: string;
}

@Component({
  selector: 'app-cart-wizard',
  templateUrl: './cart-wizard.component.html',
  styleUrls: ['./cart-wizard.component.scss'],
})

export class CartWizardComponent implements OnInit, OnDestroy {
  @ViewChild(WizardComponent) public wizard!: WizardComponent;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChildren('ProdInputComponent') productInputComponents!: QueryList<ProductInputComponent>;

  // Constants
  PRODUCT_TYPES = PRODUCT_TYPES;
  PRODUCT_FAMILY = PRODUCT_FAMILY;
  CART_STEPS = CART_STEPS;

  ////
  product: IProductMain = {} as IProductMain;
  productFinalized: IProductFinalized = {} as IProductFinalized;
  @ViewChild('stickyBox') stickyBox!: ElementRef;

  // Numbers
  chosenPayment: number = -1;
  selectedStartTime: number = 0;
  private stickyBoxOriginalPosition!: number;

  // Strings
  couponCode: string = '';
  clientEmail: string = '';
  locationTitle: string = '';
  brandPlaceholder: string = '';

  // Forms
  cartForm!: FormGroup;
  campForm!: FormGroup;
  paymentForm: FormGroup;

  roleOptions: any = {};
  
  // Arrays
  clientOwnerOf = new Array<string>();
  holdClients = new Array<IClient>();
  allMyClients = new Array<IClient>();
  mapKeys = new Map<string, Array<Map<string, string>>>();
  public userRoles: Array<number> = [];
  activeUser: IUser = {} as IUser;
  saveCardId = new String();
  uniqueCouponCodes: Array<string> = [];
  myPaymentMethods: Array<any> = new Array<any>();
  allCompanies: Array<ICompany> = new Array<ICompany>();
  filteredCampaigns = new Map<string, Array<ICampaign>>();
  chosenCampaigns = new Array<ICampaign>();
  productAdSpendOptions = new Map<string, Array<number>>();
  finalizedProducts = new Array<IProductFinalized>();

  paymentSubmitted = false;
  selectingDate: boolean = false;
  addingGoogle: boolean = false;
  addingFacebook: boolean = false;
  addingConsulting: boolean = false;
  beenHere: boolean = false;
  attemptClick: boolean = false;
  hasGoogle: boolean = false;
  hasFacebook: boolean = false;
  requiredInfoCompleted: boolean = false;
  isNewCampaign: boolean = false;
  isOptionSelected: boolean = false;
  isAdmin: boolean = false;
  //

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  coupons: Coupon[] = [];
  activeCart: ICart = {} as ICart;
  cartStepData: CartStepData = {} as CartStepData;
  couponResponse: CouponResponse = {} as CouponResponse;

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private shopService: ShopApiService,
    private utilService: UtilityService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router,
    private datafeedService: DataFeedService,
    private campaignApiService: CampaignsApiService,
    private modalService: NgbModal,
    private location: Location,
    private paymentMethodApi: PaymentMethodApiService,
    private clientService: ClientsApiService,
    public uService: UtilityService
  ) {
    this.userRoles = [...this.uService.getUserRoles()];

    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.activeUser = u;
    });
    
    this.cartForm = this.formBuilder.group({
      cartId: [null],
      productId: [null],
      adSpend: [null],
      couponCode: [null, [Validators.required]],
      couponId: [null],
      stepsData: this.formBuilder.array([])
    });

    this.campForm = this.formBuilder.group({
        campaignId: [null, [Validators.required]],
        clientId: [null, [Validators.required]],
        brandName: [null],
        studioLocationName: [null],
        campaignName: [null],
        studioEmail: [null],
        studioPhone: [null],
        studioStreet: [null],
        studioCity: [null],
        studioState: [null],
        studioZipcode: [null],
        fbPageUrl: [null],
        crmName: [null],
        crmId: [null],
        notes: [null],
        isFacebook: [null],
        isGoogle: [null]
      });

      this.paymentForm = this.formBuilder.group({
        methodId: [null, [Validators.required]],
        clientId: [null, [Validators.required]],
        nickname: [null],
        firstName: [null],
        lastName: [null, []],
        cardNumber: [null, []],
        cardCVV: [null],
        cardExpiryDate: [null],
        agreeTerms: [null, [Validators.requiredTrue]],
        address: [null, [Validators.required]],
        city: [null, [Validators.required]],
        state: [null, [Validators.required]],
        zip: [null, [Validators.required]],
      });

      this.fc.campaignId.setValidators(this.isNewCampaign ? [] : [Validators.required]);
      var validators = this.isNewCampaign ? [Validators.required] : [];
      this.fc.brandName.setValidators(validators);
      this.fc.studioLocationName.setValidators(validators);
      this.fc.studioEmail.setValidators(validators);
      this.fc.studioPhone.setValidators(validators);
      this.fc.studioStreet.setValidators(validators);
      this.fc.campaignId.updateValueAndValidity();
      this.fc.brandName.updateValueAndValidity();
      this.fc.studioLocationName.updateValueAndValidity();
      this.fc.studioEmail.updateValueAndValidity();
      this.fc.studioPhone.updateValueAndValidity();
      this.fc.studioStreet.updateValueAndValidity();
      this.fc.crmName.updateValueAndValidity();
      this.fc.crmId.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.datafeedService.getAssociatedCompanies()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.good) {
          this.allCompanies = [...response.data];
          var checkCompanies = new Array<ICompany>();
          this.allCompanies.forEach((x: ICompany) => {
            if ((checkCompanies.find(y => y.id === x.id) === undefined)) {
              checkCompanies.push(x);
            }
          });
          this.allCompanies = checkCompanies;
        }
      });

    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        var r = getRolesForPage(u.roles, PAGE_NAMES.Clients);
        this.isAdmin = r.isAdmin;
        this.clientOwnerOf = [...u.clientOwnerOf];
        if (r.options) {
          this.roleOptions = JSON.parse(r.options!);
        }
      });

    let cartId = this.route.snapshot.paramMap.get('id')!;

    if (cartId) {
      this.shopService.getCartDetails(cartId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((cart: any) => {
          this.activeCart = cart.data;
          if (cart.data.currentStep > 1) {
            this.router.navigate(['/pages/shop']);
          }
          this.appService.clearCartProductDetails$();
          this.appService.fetchCartProductDetails(cart.data.productId);

          this.appService.getCartProductDetails$()
            .pipe(filter(x => !_.isEmpty(x)))
            .subscribe((prod: IProductMain) => {
              this.product = _.clone(prod);
              this.product.media.src = this.utilService.byteToImageSrc(prod.media.blob);
              if (cart.data.currentStep === CART_STEPS.BundleDetails) {
                if (this.product.familyCode !== PRODUCT_FAMILY.Bundle) {
                  let pp = _.clone(this.product);
                  pp.productsInBundle = [];
                  this.product.productsInBundle = [pp];
                }
                let i = 0;
                let amountsArr = new Array<number>();
                _.sortBy(this.product.productsInBundle, "sortOrder").forEach((p: ICartProductDetails) => {
                  if (p.type === PRODUCT_TYPES.VariableSubscription.code) {
                    amountsArr = p.variableSubscription.map(x => x.amount).sort((n1, n2) => n1 - n2);
                    if (this.product.familyCode === PRODUCT_FAMILY.Bundle) {
                      p.bundleId = this.product.id!;
                      p.bundleName = this.product.name!;
                    }
                    this.productAdSpendOptions.set(p.id, amountsArr);
                  }
                  p.stepCode = this.activeCart.currentStep;
                  (p.media as IProductMedia).src = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + p.media.blob);
                  this.addStepData(p);
                  this.stepsData.at(i).get('adSpend')?.setValue(amountsArr[0])
                  i++;
                });
                this.onNext(1);
              }
              else if (cart.data.currentStep === CART_STEPS.RequiredInfo) {
                if (this.product.type !== PRODUCT_FAMILY.Bundle) {
                  let pp = _.clone(this.product);
                  pp.productsInBundle = [];
                  this.product.productsInBundle = [pp]
                }
                let formValues = cart.data.cartData.find((x: any) => x.stepCode === CART_STEPS.RequiredInfo)?.formData;
                if (formValues) {
                  let parsedFormValues = JSON.parse(formValues);
                  setTimeout(() => {
                    for (let prodComp of this.productInputComponents.toArray()) {
                      let formValues = parsedFormValues.find((x: any) => x.familyCode === prodComp.getFormKey());
                      if (formValues)
                        prodComp.setFormValues(formValues);
                    }
                  }, 1);
                }
              }
              else if (cart.data.currentStep === CART_STEPS.Cart) {
                if (this.product.type !== PRODUCT_FAMILY.Bundle) {
                  let pp = _.clone(this.product);
                  pp.productsInBundle = [];
                  this.product.productsInBundle = [pp]
                }
                let ddArray = JSON.parse(cart.data.cartData.find((x: any) => x.stepCode === CART_STEPS.Cart)?.formData);
                if (ddArray[0]) {
                  this.cartStepData = ddArray[0] as CartStepData
                }
                else {
                  this.cartStepData.subtotal = this.product.productsInBundle.map((x: any) => x.data.price).reduce((s: number, f: number) => {
                    return s + f;
                  }, 0);
                  this.cartStepData.total = this.cartStepData.subtotal;
                }
                if (ddArray[1]) {
                  this.couponResponse = ddArray[1] as CouponResponse
                }
                this.cartForm.patchValue({ couponCode: this.couponResponse.code })
              }
              this.doFinalizeProducts();
            });
          this.cartForm.patchValue({
            cartId: cart.data.id,
            productId: this.activeCart.productId,
          });
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  get fc() { return this.campForm.controls; }

  get fp() { return this.paymentForm.controls! }

  public get stepsData(): FormArray {
    return this.cartForm.get('stepsData') as FormArray
  }

  addStepData(cartProduct: ICartProductDetails) {
    const newStepData = this.formBuilder.group({
      id: cartProduct.id,
      signupFee: cartProduct.data.signupFee,
      display: cartProduct.display,
      familyCode: cartProduct.familyCode,
      description: cartProduct.description,
      stepCode: cartProduct.stepCode,
      type: cartProduct.type,
      mediaUrl: cartProduct.media.src,
      required: false,
      duration: cartProduct.data.duration,
      includeProduct: [true],
      isGoogle: cartProduct.isGoogle,
      isFacebook: cartProduct.isFacebook,
      price: cartProduct.data.price,
      frequency: cartProduct.data.frequency,
      bundleId: cartProduct.bundleId,
      bundleName: cartProduct.bundleName,
      isRequired: cartProduct.isRequired,
      adSpend: [1],
    })
    this.stepsData.push(newStepData);
  }

  // Payment
  // Checking and Changing Payment Info
  onPaymentClientChange(clientId: any) {
    this.chosenPayment = -1;
    var submitId = (clientId.id !== undefined) ? clientId.id : clientId;
    
    const sub = this.datafeedService.getPaymentMethodsByClientId(submitId)
    .subscribe(res => {
      res.data.map((x: any) => x.cardNumber = `****${x.cardNumber}`)
      this.myPaymentMethods = [...res.data];
      this.checkPayment();
      setTimeout(() => { sub.unsubscribe(); }, 0);
    });
    
    this.paymentForm.patchValue({
      methodId: null,
      address: null,
      city: null,
      state: null,
      zip: null,
    });
  }

  // Payment Method Change
  onPaymentMethodChange(pid: any) {
    if (pid && this.chosenPayment !== -1) {
      let e = this.myPaymentMethods.find(x => x.id === pid);
      this.paymentForm.patchValue({
        methodId: pid,
        address: e.address,
        city: e.city,
        state: e.state,
        zip: e.zip,
      });
    } else {
      this.paymentForm.patchValue({
        methodId: null,
        address: null,
        city: null,
        state: null,
        zip: null,
      });
    }
  }

  // Check for valid date.
  checkPayment() {
    const currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth() + 1;
    this.myPaymentMethods.forEach((x: any) => {
      this.paymentMethodApi.getPaymentMethod(x.id)
      .pipe(takeUntil(this.destroyed$)).subscribe((response: any) => {
        if(response.paymentMethod.paymentType == 'Card'){
          if ((Number(response.paymentMethod.expiry.substring(2, 4) > Number(String(currentYear).substring(2, 4)))) || ((Number(response.paymentMethod.expiry.substring(2, 4) == Number(String(currentYear).substring(2, 4)))) && (Number(response.paymentMethod.expiry.substring(0, 2) >= currentMonth)))) {
            x.expiry = response.paymentMethod.expiry.substring(0, 2) + '/' + response.paymentMethod.expiry.substring(2, 4);
            if (x.id === this.saveCardId) {
              this.choosePayment(this.myPaymentMethods.indexOf(x));
              this.onPaymentMethodChange(x.id);
            }
          }
        }
        else{
          x.expiry = '';
          if (x.id === this.saveCardId) {
            this.choosePayment(this.myPaymentMethods.indexOf(x));
            this.onPaymentMethodChange(x.id);
          }
        }
      });
    });
  }

  //
  doSubmitPaymentForm() {
    this.paymentSubmitted = true;
    if (this.paymentForm.invalid)
      return false;
    return true;
  }

  //
  getPaymentFormValues() {
    if (!this.paymentForm.invalid)
      return this.paymentForm.value;
  }

  // Adding New Method
  onAddNewPaymentMethod() {
    const dialogRef = this.modalService.open(AddPaymentMethodModal, { size: 'lg', backdrop: 'static' });
    dialogRef.componentInstance.isShopOrder = true;
    dialogRef.componentInstance.oSaveClicked.subscribe((response: any) => {
      this.saveCardId = response.paymentMethod.id;
      this.onPaymentClientChange(this.paymentForm.controls["clientId"].value);
    });
  }

  //
  choosePayment(newIndex: number) {
    if (this.chosenPayment === newIndex) {
      this.chosenPayment = -1;
    }
    else {
      this.chosenPayment = newIndex;
    }
  }

  // Campaign & Naming (Part 1)
  onNameChange(evt: any) {
    let a = this.fc.brandName?.value || '';
    let b = this.fc.studioLocationName?.value || '';
    this.fc.campaignName.setValue(a + ' ' + b);
  }

  // Adding Coupon
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.coupons.push({name: value});
    }
    event.chipInput!.clear();
  }
  
  // Removing Coupon
  remove(coupon: Coupon): void {
    const index = this.coupons.indexOf(coupon);
    if (index >= 0) {
      this.coupons.splice(index, 1);
    }
  }

  // Selecting Date (Date Part 1???)
  clickDate(newDate: number) {
    if (this.selectingDate || this.selectedStartTime === 0) {
      this.selectedStartTime = newDate;
      if (newDate === 2) {
        // this.updateCartProductPrices('Firsst');
        if (this.coupons.find(x => x.name.toLowerCase() === 'first') !== undefined) {
          this.remove(this.coupons.find(x => x.name.toLowerCase() === 'first')!);
        }
        this.coupons.push({name: 'first'});
        this.onApplyCoupon();
      }
      else {
        if (this.coupons.find(x => x.name.toLowerCase() === 'first') !== undefined) {
          this.remove(this.coupons.find(x => x.name.toLowerCase() === 'first')!);
        }
        this.onApplyCoupon();
      }
      this.selectingDate = false;
    }
  }

  //
  onApplyCoupon() {
    this.cartForm.controls['couponCode'].updateValueAndValidity();
    var couponStr = Array.prototype.map.call(this.coupons, s => s.name).toString(); // "1,2,3"
    this.updateCartProductPrices(couponStr);
    return;
  }

  // GEt CAmpaigns (PArt 1?)
  getCampaignsByClient(clientId: string) {
    this.campForm.patchValue({ campaignId: null });
    this.campaignApiService.getCampaignsByClient(clientId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => {
        if (res.good) {
          const updatedCampaigns = res.data.map((x: any) => {
            x.disabled = x.rmSubscriptionId != null && x.rmSubscriptionId.length > 0;
            x.subscriptionId = x.rmSubscriptionId;
            return x;
          });
          updatedCampaigns.forEach((x: ICampaign) => {
            if (x.platform === 'Facebook') {
              var name = x.name.substring(0, x.name.indexOf(' FB'));
            }
            else {
              var name = x.name;
            }
            if (this.filteredCampaigns.get(name) === undefined) {
              this.filteredCampaigns.set(name, []); // Initialize an array for this campaign name if it doesn't exist
            }
            const group = this.filteredCampaigns.get(name); // Add the current campaign to its respective group
            group?.push(x);
            if (this.mapKeys.get(clientId) === undefined) {
              this.mapKeys.set(clientId, new Array<Map<string,string>>());
            }
            // checkDup is now the array of Maps of string
            var checkDup = this.mapKeys.get(clientId);
            if (checkDup!.find(x => x.get('name') === name) === undefined) {
              this.mapKeys.get(clientId)?.push(new Map([["name", name], ["platform", x.platform]]));
              this.mapKeys.get(clientId)?.sort((a, b) => a.get('name')!.localeCompare(b.get('name')!));
            }

            // if (checkDup!.includes(name) === false) {
            //   this.mapKeys.get(clientId)?.push(name);
            //   this.mapKeys.get(clientId)?.sort((a, b) => a!.localeCompare(b!));;
            // }
          });
        }
      });
  }

  // Products
  doFinalizeProducts() {
    this.finalizedProducts = [];
    for (let ctrl of this.stepsData.controls) {
      let id = (ctrl as FormGroup).controls.id.value;
      let include = (ctrl as FormGroup).controls.includeProduct?.value;
      if (include) {
        let fProduct = this.product.productsInBundle.find(x => x.id === id) as IProductFinalized;
        if (fProduct !== undefined) {
          if (fProduct.bundleId === undefined) {
            fProduct.bundleId = '';
          }
          if ((fProduct.type !== undefined) && (fProduct.type === PRODUCT_TYPES.VariableSubscription.code)) {
            fProduct.adSpend = (ctrl as FormGroup).controls.adSpend?.value;
            if (typeof (fProduct.adSpend) === 'string') {
              fProduct.adSpend = parseInt(fProduct.adSpend);
            }
          }
          if ((this.product !== undefined) && (this.product.type === PRODUCT_FAMILY.Bundle)) {
            if (this.product !== undefined) {
              if (this.product.id !== undefined) {
                fProduct.bundleId = this.product.id;
              }
              if (this.product.name !== undefined) {
                fProduct.bundleName = this.product.name;
              }
            }
          }
          this.finalizedProducts.push(fProduct);
        }
      }
    }
    if (this.finalizedProducts.filter(x => x.familyCode !== PRODUCT_FAMILY.AdSpend).length === 0) {
      this.requiredInfoCompleted = true;
    }
  }

  // DATES PART 2
  showRecurring() {
    return this.finalizedProducts.findIndex(x => x.type === PRODUCT_TYPES.SimpleSubscription.code || x.type === PRODUCT_TYPES.VariableSubscription.code) >= 0;
  }

  //
  getRecurringDate(): any {
    var recurringDate = this.finalizedProducts.find(x => x.type === PRODUCT_TYPES.SimpleSubscription.code || x.type === PRODUCT_TYPES.VariableSubscription.code)?.recurringStartDate;
    if (recurringDate === undefined) {
      recurringDate = '';
    }
    return recurringDate;
  }

  // 
  getStartDate(): any {
    var startDate = this.finalizedProducts.find(x => x.type === PRODUCT_TYPES.SimpleSubscription.code || x.type === PRODUCT_TYPES.VariableSubscription.code)?.prorateSDate;
    if (startDate === undefined) {
      startDate = new Date(1900);
    }
    return startDate;
  }

  //
  getEndDate(): any {
    var endDate = this.finalizedProducts.find(x => x.type === PRODUCT_TYPES.SimpleSubscription.code || x.type === PRODUCT_TYPES.VariableSubscription.code)?.prorateEDate;
    if (endDate === undefined) {
      endDate = new Date(1900);
    }
    return endDate;
  }

  // STEPS
  async onStepEnter(evt: any, stepCode: number) {
    if (this.activeCart && this.activeCart.currentStep && this.activeCart.currentStep !== stepCode) {
      this.shopService.updateCartStep(this.activeCart.id, stepCode)
        .subscribe((r: any) => {
          if (r.good) {
            this.activeCart.currentStep = stepCode;
          }
        });
    }
    if (stepCode === CART_STEPS.BundleDetails) {
      if (this.beenHere) {
        this.router.navigate(['/pages/shop']);
      }
      this.beenHere = true;
    }
    if (stepCode === CART_STEPS.RequiredInfo) {
      if (this.coupons.find(x => x.name.toLowerCase() === 'first') !== undefined) {
        this.remove(this.coupons.find(x => x.name.toLowerCase() === 'first')!);
      }
      await this.checkHasPlatform();
      window.scrollTo(0, 0);
      this.isOptionSelected = false;
      this.requiredInfoCompleted = false;
      this.isNewCampaign = false;
      this.campForm.controls["clientId"].setValue('');
      this.paymentForm.controls["clientId"].setValue('');
      this.locationTitle = '';
      this.hasGoogle = false;
      this.hasFacebook = false;
      if (this.finalizedProducts.filter(x => x.familyCode !== PRODUCT_FAMILY.AdSpend).length > 0) {
        this.datafeedService.getClientsFeedByIds(this.clientOwnerOf)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((res: any) => {
            if (res.good) {
              res.data.map((x: IClient) => x.fullName = x.firstName + ' ' + x.lastName)
              this.allMyClients = [...res.data];
              this.holdClients = this.allMyClients;
              if (!this.userRoles.includes(1) && !this.userRoles.includes(4)) {
                // this.checkoutClients = this.allMyClients;
                this.allMyClients.forEach((x: IClient) => {
                  this.getCampaignsByClient(x.id);
                });
              }
            }
          });
      }
    }
    else if (stepCode === CART_STEPS.Cart) {
      if (this.coupons.find(x => x.name.toLowerCase() === 'first') !== undefined) {
        this.remove(this.coupons.find(x => x.name.toLowerCase() === 'first')!);
      }
      window.scrollTo(0, 0);
      this.paymentSubmitted = false;
      this.chosenPayment = -1;
      this.paymentForm.patchValue({
        methodId: null,
        address: null,
        city: null,
        state: null,
        zip: null,
      });
      this.fp.agreeTerms.reset();
      this.selectedStartTime = 0;

      setTimeout(() => {
        this.stickyBoxOriginalPosition = this.stickyBox.nativeElement.offsetTop;
      });
      this.updateCartProductPrices(null);
    }
  }
  
  // VISUAL?
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.activeCart.currentStep===CART_STEPS.Cart) {
      const stickyBoxCurrentPosition = window.pageYOffset + this.stickyBox.nativeElement.getBoundingClientRect().top;
      if (window.pageYOffset > this.stickyBoxOriginalPosition + 200) {
        this.stickyBox.nativeElement.style.position = 'fixed';
        this.stickyBox.nativeElement.style.top = '90px';
        this.stickyBox.nativeElement.style.right = '65px';
      } else {
        this.stickyBox.nativeElement.style.position = 'relative';
        this.stickyBox.nativeElement.style.top = `${this.stickyBoxOriginalPosition}px`;
        this.stickyBox.nativeElement.style.right = '0px';
      }
    }
  }

  // FILL
  continueToCart() {
    if (((!this.hasFacebook) && (!this.hasGoogle)) || (!this.addingFacebook && !this.addingGoogle)) {
      window.scrollTo(0, 0);
      if (!this.showRecurring()) {
        this.selectedStartTime = 1;
        this.updateCartProductPrices(null);
      }
      else {
        this.updateCartProductPrices(null);
      }
      if (this.myPaymentMethods.length === 1) {
        this.choosePayment(0); 
        this.onPaymentMethodChange(this.myPaymentMethods[0].id);
      }
      this.onNext(CART_STEPS.Cart);
      this.wizard.goToNextStep();
    }
  }

  // FIll && NAVIGATION
  onNext(stepCode: number) {
    let thisStepData = this.activeCart.cartData.find(x => x.stepCode == stepCode)!

    if (stepCode === CART_STEPS.BundleDetails) {
      let formData = this.activeCart;
      thisStepData.formData = JSON.stringify(this.stepsData.value);
      this.shopService.saveCart(formData)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((x: any) => {
          if (x.good) {
            this.wizard.goToNextStep();
            this.activeCart.currentStep = stepCode + 1;
          }
        })
    }
    else if ((stepCode === CART_STEPS.RequiredInfo)) {
      if ((this.isNewCampaign && ((this.fc.studioLocationName.value !== null) && (this.fc.clientId.value !== null) && (this.fc.clientId.value !== '') && (this.fc.brandName.value !== null) && (this.fc.studioEmail.value !== null) && (this.fc.studioPhone.value !== null) && (this.fc.studioStreet.value !== null))) || !this.isNewCampaign) {
        this.attemptClick = false;
        this.fc.campaignId.setValidators([]);
        var validators = this.isNewCampaign ? [Validators.required] : [];
        this.fc.brandName.setValidators(validators);
        this.fc.studioLocationName.setValidators(validators);
        this.fc.studioEmail.setValidators(validators);
        this.fc.studioPhone.setValidators(validators);
        this.fc.studioStreet.setValidators(validators);
        this.fc.campaignId.updateValueAndValidity();
        this.fc.brandName.updateValueAndValidity();
        this.fc.studioLocationName.updateValueAndValidity();
        this.fc.studioEmail.updateValueAndValidity();
        this.fc.studioPhone.updateValueAndValidity();
        this.fc.studioStreet.updateValueAndValidity();
        this.fc.crmName.updateValueAndValidity();
        this.fc.crmId.updateValueAndValidity();

        if (this.fc.brandName.value !== null) {
          this.fc.brandName.setValue(this.fc.brandName.value.trim());
        }
        if (this.fc.campaignName.value !== null) {
          this.fc.campaignName.setValue(this.fc.campaignName.value.trim());
        }
        if (this.fc.crmId.value !== null) {
          this.fc.crmId.setValue(this.fc.crmId.value.trim());
        }
        if (this.fc.crmName.value !== null) {
          this.fc.crmName.setValue(this.fc.crmName.value.trim());
        }
        if (this.fc.fbPageUrl.value !== null) {
          this.fc.fbPageUrl.setValue(this.fc.fbPageUrl.value.trim());
        }
        if (this.fc.studioStreet.value !== null) {
          this.fc.studioStreet.setValue(this.fc.studioStreet.value.trim());
        }
        if (this.fc.studioCity.value !== null) {
          this.fc.studioCity.setValue(this.fc.studioCity.value.trim());
        }
        if (this.fc.studioState.value !== null) {
          this.fc.studioState.setValue(this.fc.studioState.value.trim());
        }
        if (this.fc.studioZipcode.value !== null) {
          this.fc.studioZipcode.setValue(this.fc.studioZipcode.value.trim());
        }
        if (this.fc.studioEmail.value !== null) {
          this.fc.studioEmail.setValue(this.fc.studioEmail.value.trim());
        }
        if (this.fc.studioLocationName.value !== null) {
          this.fc.studioLocationName.setValue(this.fc.studioLocationName.value.trim());
        }
        if (this.fc.studioPhone.value !== null) {
          this.fc.studioPhone.setValue(this.fc.studioPhone.value.trim());
        }
        
        if (this.isNewCampaign) {
          this.campForm.controls["campaignId"].setValue(null);
          this.locationTitle = this.campForm.controls['campaignName'].value;
        }
        else {
          this.campForm.controls["campaignId"].setValue(this.chosenCampaigns[0].id)
        }
        var isConsulting = false;
        this.stepsData.controls.forEach((x: any) => {
          if (x.value.display.includes('Consulting')) {
            isConsulting = true;
          }
        });
        if (!this.addingFacebook && !this.addingGoogle && isConsulting) {
          this.locationTitle = this.locationTitle + ' Campaign Consulting';
          this.campForm.controls['campaignName'].setValue(this.campForm.controls['campaignName'].value + ' Campaign Consulting');
        }

        this.appService.getActiveUser$()
          .pipe(takeUntil(this.destroyed$))
          .subscribe(u => {
            if ((this.campForm.controls["clientId"].value == null) || !this.isNewCampaign) {
              this.campForm.controls["clientId"].setValue(this.allMyClients[0].id);
              this.paymentForm.controls["clientId"].setValue(this.allMyClients[0].id);
              this.onPaymentClientChange(this.allMyClients[0].id);
            }
            else {
              this.paymentForm.controls["clientId"].setValue(this.campForm.controls["clientId"].value);
              this.onPaymentClientChange(this.campForm.controls["clientId"].value);
            }
        });

        var isCampValid = this.campForm.valid;
        if (isCampValid) {
          this.activeCart.currentStep = CART_STEPS.RequiredInfo;

          if (thisStepData === undefined) {
            this.activeCart.cartData.push({
              stepCode: CART_STEPS.RequiredInfo,
              formData: JSON.stringify(this.campForm.value)
            } as ICartData)
          }
          else {
            thisStepData.formData = JSON.stringify(this.campForm.value);
          }
          this.shopService.saveCart(this.activeCart)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((x: any) => {
              if (x.good) {
                this.wizard.goToNextStep();
                this.activeCart.currentStep = stepCode + 1;
              }
            })
        }
      }
    }
    else if (stepCode === CART_STEPS.Cart) {
      this.activeCart.currentStep = CART_STEPS.Cart;
      let infoFormData = [this.cartStepData, this.couponResponse];
      if (thisStepData === undefined) {
        this.activeCart.cartData.push({
          stepCode: CART_STEPS.Cart,
          formData: JSON.stringify(infoFormData)
        } as ICartData);
        this.onNext(CART_STEPS.Cart);
      }
      else {
        thisStepData.formData = JSON.stringify(infoFormData);
        this.shopService.saveCart(this.activeCart)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((x: any) => {
            if (x.good) {
            }
          });
        }
    }
    else if (stepCode === CART_STEPS.Checkout) {
      this.clientService.getClientDetails(this.paymentForm.controls["clientId"].value)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.clientEmail = response.data.email;
        });

      let readyForCheckout = this.doSubmitPaymentForm();
      if (readyForCheckout) {
        this.activeCart.currentStep = CART_STEPS.Checkout;
        // right here - gotta change the order amount depending on the product
        let formData = [{ ...this.getPaymentFormValues(), orderAmount: this.cartStepData.total.toFixed(2) }];
        if (thisStepData === undefined) {
          // turn around if invalid
          if (this.finalizedProducts[0].type !== PRODUCT_FAMILY.Bundle) {
            this.finalizedProducts = [...this.finalizedProducts];
          }
          this.finalizedProducts.map(x => { x.media.blob = '', x.media.src = '' });
          this.activeCart.cartData.push({
            stepCode: CART_STEPS.Checkout,
            formData: JSON.stringify(formData),
            productsData: JSON.stringify(this.finalizedProducts),
            couponId: (this.couponResponse.couponApplied && this.couponResponse.couponApplied === true) ? this.couponResponse.id : null
          } as ICartData);
        }
        else {
          thisStepData.formData = JSON.stringify(formData);
        }
        this.shopService.saveCart(this.activeCart)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((x: any) => {
            if (x.good) {
              if (x.data.response.transactionStatus === TRX_STATUS.TransactionApproved) {
                this.router.navigate(['/pages/shop/ordercomplete', 1, x.data.response.orderNumber, x.data.response.orderId, this.clientEmail, this.getRecurringDate()]);
              }
              else if (x.data.response.transactionStatus === TRX_STATUS.TransactionDeclined || x.data.response.transactionStatus === TRX_STATUS.Error ) {
                this.router.navigate(['/pages/shop/ordercomplete', 0, x.data.response.orderNumber, x.data.response.orderId,]);
              }
            }
          });
      }
    }
  }

  // Client Change ??
  onClientChange(event: any) {
    if ((event.fullName !== '') && (event.fullName !== undefined) && (event.fullName !== null)) {
      this.filteredCampaigns = new Map<string, Array<ICampaign>>();
      // this.mapKeys = new Map<string, Array<string>>();
      this.mapKeys = new Map<string, Array<Map<string,string>>>();
      this.allMyClients = this.holdClients.filter(x => x.fullName.toLowerCase().includes(event.fullName.toLowerCase()));
      this.allMyClients.forEach((x: IClient) => {
        this.getCampaignsByClient(x.id);
      });
    } 
  }

  // NAVIGATION (Part 1??)
  onPrevious(stepCode: number) {
    this.shopService.updateCartStep(this.activeCart.id, stepCode)
      .subscribe((r: any) => {
        if (r.good) {
          this.wizard.goToPreviousStep();
          this.activeCart.currentStep = stepCode;
        }
      });
  }

  // To The Cart (Part 2??)
  updateCartProductPrices(couponCode: any) {
    var yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - (offset*60*1000));
    let todayAtUserComputer = yourDate.toISOString().split('T')[0];
    let getProductPriceRequest: { productIds: string[], productsAdSpend: any[], couponCode: any, clientId: any, todayAtUserComputer: string }
      = { productIds: [], productsAdSpend: [], couponCode: couponCode, clientId: null, todayAtUserComputer };
    
    for (let p of this.finalizedProducts) {
      getProductPriceRequest.productIds.push(p!.id);
      getProductPriceRequest.productsAdSpend.push(p.adSpend);
    }
    
    this.shopService.getCartProductPrices(getProductPriceRequest)
      .subscribe((res: any) => {
        for (let responseProduct of res.data.cartProductPrices) {
          let p = this.finalizedProducts.find(x => x.id === responseProduct.productId);
          p!.proratePrice = responseProduct.proratedAmount;
          p!.prorateSDate = responseProduct.prorateStartDate;
          p!.prorateEDate = responseProduct.prorateEndDate 
          p!.recurringPrice = responseProduct.recurringPrice;
          p!.recurringStartDate = responseProduct.recurringStartDate;
          p!.isProrated = responseProduct.isProrated;
          if (responseProduct.discount) {
            p!.couponAmount = responseProduct.discount;
            p!.couponApplied = true;
            p!.finalPrice = p!.proratePrice - p!.couponAmount!;
            p!.couponCodes = responseProduct.coupon;
          }
          else {
            p!.couponAmount = undefined;
            p!.couponApplied = false;
            p!.finalPrice = p!.proratePrice;
            p!.couponCodes = "";
          }
        }
        this.couponResponse = {
          isValid: res.data.couponValid,
          code: getProductPriceRequest.couponCode,
          responseMessage: res.data.couponInvalidationReason,
          id: res.data.couponId
        };
        if (this.couponResponse.isValid) {
          this.couponResponse.couponApplied = true;
          this.productFinalized.couponApplied = true;
        }
        this.calculateCartTotalPrices(res.data.discount, res.data);
      })
  }

  // NAVIGATION (Part ??)
  selectedCampaign(clientId: string, campaign: Map<string, string>) {
    if (this.checkCampPlatforms(campaign)) {
      this.isNewCampaign = false;
      this.campForm.controls["clientId"].setValue(clientId);
      this.paymentForm.controls["clientId"].setValue(clientId);
      this.onPaymentClientChange(clientId);
      this.locationTitle = campaign.get('name')!;
      this.setCampIds(campaign); 
      this.onNext(CART_STEPS.RequiredInfo);
    }
  }

  // Checking ??
  async checkHasPlatform() {
    this.stepsData.controls.forEach((x: any) => {
      if (x.controls.display.value.includes('Google')) {
        this.addingGoogle = true;
      }
      if (x.controls.display.value.includes('Meta')) {
        this.addingFacebook = true;
      }
      if (x.controls.display.value.includes('Consulting')) {
        this.addingConsulting = true;
      }
    });
  }

  // Sorting ??
  checkCampPlatforms(campaign: Map<string, string>) {
    var returningFalse = true;

    if (campaign.get('platform')!.toLowerCase().includes('consulting')) {
      if (!this.addingConsulting) {
        returningFalse = false;
      }
      else {
        this.filteredCampaigns.get(campaign!.get('name')!)!.forEach((x: ICampaign) => {
          if ((((x.subscriptionActive != null) && (x.subscriptionActive)))) {
            returningFalse = false;
          }
        });
      }
    } else if (campaign.get('platform')!.toLowerCase().includes('national add on')) {
      if (this.addingFacebook || this.addingGoogle) {
        returningFalse = false;
      }
    }
    else {
      if (this.addingConsulting) {
        returningFalse = false;
      } 
      else {
        this.filteredCampaigns.get(campaign.get('name')!)!.forEach((x: ICampaign) => {
            if (((x.platform === 'Facebook') || (x.platform === 'Google')) && (((x.subscriptionActive != null) && (x.subscriptionActive)))) {
              returningFalse = false;
            }
        });
      }
    }
    
    return returningFalse;
  }

  // SETTING ??
  setCampIds(campaign: Map<string, string>) {
    this.chosenCampaigns = this.filteredCampaigns.get(campaign.get('name')!)!;
    this.chosenCampaigns.forEach((x: ICampaign) => {
      this.campaignApiService.getCampaignDetails(x.id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          if (response.data.subscriptionActive) {
            if (x.platform === 'Google') {
              this.hasGoogle = true;
            } else if (x.platform === 'Facebook') {
              this.hasFacebook = true;
            }
          }
      });
    });
  }

  // Cart (Part 3??)
  calculateCartTotalPrices(discountAmt: number, data: any) {
    let total = 0;
    let recurringTotal = 0;
    let discountTotal = 0;
    let couponCodes = '';
    let cartCouponCodes = '';
    let cartDiscountTotal = 0;
    this.uniqueCouponCodes = [];

    for (let product of this.finalizedProducts) {
      total += product.proratePrice;
      if (product.data.signupFee) {
        total += product.data.signupFee;
      }
      if (product.couponAmount) {
        discountTotal += product.couponAmount
      }
      recurringTotal += product.recurringPrice;
    }

    if (data != null && data.couponsDetails != null) {
      for (let coupon of data.couponsDetails) {
        if (coupon.productId === null) {
          cartDiscountTotal += coupon.discountAmount;
          cartCouponCodes += cartCouponCodes.length > 0 ? ',' + coupon.couponCode : coupon.couponCode;
          this.uniqueCouponCodes.push(coupon.couponCode);
        }
        else {
          if (couponCodes.indexOf(coupon.couponCode) < 0) {
            couponCodes += couponCodes.length > 0 ? ',' + coupon.couponCode : coupon.couponCode;
            this.uniqueCouponCodes.push(coupon.couponCode);
          }
        }
      }
    }

    this.product.totalRecurringPrice = recurringTotal;
    this.product.total = total;
    this.productFinalized.couponAmount = discountTotal;
    this.cartStepData = {
      ...this.cartStepData,
      subtotal: this.product.total,
      productDiscount: discountTotal,
      cartDiscount: cartDiscountTotal,
      productCoupon: couponCodes,
      cartCoupon: cartCouponCodes,
      total: this.product.total - (discountTotal + cartDiscountTotal),
      recurringTotal: this.product.totalRecurringPrice,
      couponDetails: data != null && data.couponsDetails != null ? data.couponsDetails : null
    };
  }

  // EDT?? What? Part what??
  openEdit(id: any) {
    const dialogRef = this.modalService.open(AddPaymentMethodModal, { size: 'lg', backdrop: 'static' });
    dialogRef.componentInstance.iId = id;
    dialogRef.componentInstance.isShopOrder = true;
    dialogRef.dismissed.subscribe(() => {
      this.location.go('/pages/myaccount/payment-methods/');
      this.onPaymentClientChange(this.paymentForm.controls["clientId"].value);
    });
    dialogRef.closed.subscribe(() => {
      this.location.go('/pages/myaccount/payment-methods/');
      this.onPaymentClientChange(this.paymentForm.controls["clientId"].value);
    });
    dialogRef.componentInstance.oSaveClicked.subscribe(() => {
      this.appService.fetchAllUserPaymentMethods();
      this.onPaymentClientChange(this.paymentForm.controls["clientId"].value);
    })
    this.location.go('/pages/myaccount/payment-methods/' + id);
  }

  // Added back
  onSwitchChange(evt: any, index: number) {
    this.stepsData.at(index).get('includeProduct')?.setValue(evt);
    this.doFinalizeProducts();
  }

  // Needed
  placeOrder() {
    this.doSubmitPaymentForm();
    if ((this.selectedStartTime !== 0) && !this.fp.methodId.errors?.required && !this.fp.agreeTerms.errors?.required && (this.uniqueCouponCodes.length == this.coupons.length)) {
      this.onNext(CART_STEPS.Cart); 
      this.onNext(CART_STEPS.Checkout);
    }
  }

  // Maybe move?
  changeCompanyFill(evt: any) {
    this.clientService.getClientDetails(evt.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.brandPlaceholder = response.data.brand;
        this.fc.brandName.setValue(response.data.brand);
        this.onNameChange(evt);
      });
  }
}

import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/http/base.http';

@Injectable()
export class SubscriptionApiService extends HttpService {

   getAllSubscriptions(campaignId?: any, clientId?: any, subscriptionIds?: string[]) {
      let query = "";
      if (campaignId) {
         query += `campaignId=${campaignId}`;
      } else if (clientId) {
         query += `clientId=${clientId}`;
      } else if (subscriptionIds) {
         query += subscriptionIds.map(id => `id=${id}`).join('&');
      }
      return this.get<Array<any>>(`/subscriptions/all?${query}`);
   }

   getSubscription(id: string) {
      return this.get('/subscriptions?id=' + id);
   }

   edit(data: any) {
      return this.post('/subscriptions/edit', data);
   }

   editCampaigns(data: any) {
      return this.post('/subscriptions/editCampaigns', data);
   }

   addProducts(data: any) {
      return this.post('/subscriptions/products', data);
   }

   addCoupon(data: any) {
      return this.post('/subscriptions/coupon', data);
   }

   removeCoupon(id: string) {
      return this.delete(`/subscriptions/coupon?subscriptionCouponId=${id}`);
   }

   cancelSubscription(subscriptionId: string) {
      return this.get(`/subscriptions/cancel?id=${subscriptionId}`);
   }

   reactiveSubscription(subscriptionId: string) {
      return this.get(`/subscriptions/reactive?id=${subscriptionId}`);
   }

   getProductsWithVariables(){
      return this.get('/products/getAllProductsWithVariable');
   }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createGuid } from '../../../core/helpers/utility';
import { ICart, IProductMain } from '../../../core/models/products.models';
import { AppService } from '../../../store/app.service';
import { ShopApiService } from '../shop.apiservice';
import { UtilityService } from '../../../core/helpers/utility.service';
import { IUser } from '../../../core/models/auth.models';
import { ClientsApiService } from '../../clients/clients.apiservice';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})

/**
 * Page Title Component
 */
export class ProductsListComponent implements OnInit, OnDestroy {
  pageNumber = 1;
  pageSize = 12;

  allProducts: Array<IProductMain> = new Array<IProductMain>();
  holdProducts: Array<IProductMain> = new Array<IProductMain>();
  tempProducts: Array<IProductMain> = new Array<IProductMain>();

  allMyCompanies: {id: number; name: string}[] = [];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public userRoles: Array<number> = [];
  activeUser: IUser = {} as IUser;
  
  doneLoading: boolean = false;

  constructor(
    private shopApiService: ShopApiService,
    private appService: AppService,
    private router: Router,
    public uService: UtilityService,
    private clientService: ClientsApiService,
  ) {
    this.userRoles = [...this.uService.getUserRoles()];
    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.activeUser = u;
      });
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Shop');

    if (this.activeUser.roles[0].code === 8 || this.activeUser.roles[0].code === 9) {
      this.appService.getAllCampaigns$()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          var checkingClients: any[] = [];
          var clientBrands: any[] = [];
          var someProducts: any[] = [];

          response.data.forEach((c: any) => {
            if (!checkingClients.find((cc: any) => cc.clientId === c.clientId)) {
              checkingClients.push({clientName: c.clientName, clientId: c.clientId,});
            }
          });
          
          checkingClients.forEach((c: any) => {
            this.clientService.getClientDetails(c.clientId)
              .pipe(takeUntil(this.destroyed$))
              .subscribe((response: any) => {
                if (!clientBrands.find((cb: any) => cb === response.data.brand)) {
                  clientBrands.push(response.data.brand);
                }
              });
          });

          this.shopApiService.getProducts()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: any) => {
              var tempProducts = response.data;

              for (var i = 0; i < tempProducts.length; i++) {
                for (var j = 0; j < clientBrands.length; j++) {
                  if (tempProducts[i].companyNames.indexOf(clientBrands[j]) > -1 && !tempProducts[i].isBundleOnly) {
                    someProducts.push(tempProducts[i]);
                  }
                }
              }

              this.allProducts = someProducts;
              this.allProducts.sort((a, b) => a.display!.localeCompare(b.display!));
              
              this.tempProducts = this.allProducts.slice(0, this.pageSize);
              this.holdProducts = this.allProducts;
              
              this.allProducts.forEach((p: IProductMain) => {
                p.companyNames.split(',').forEach((pc: string) => {
                  if (!this.allMyCompanies.find((c) => c.name === pc) && pc) {
                    this.allMyCompanies.push({id: this.allMyCompanies.length, name: pc!});
                  }
                })
              });

              this.allMyCompanies.sort((a, b) => a.name.localeCompare(b.name));
              this.doneLoading = true;
            });
        });
      
      this.appService.fetchAllCampaigns({});

    } else {
      this.shopApiService.getAllProducts()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.allProducts = [...response.data];
          this.allProducts.sort((a, b) => a.display!.localeCompare(b.display!));
          this.tempProducts = this.allProducts.slice(0, this.pageSize);
          this.holdProducts = this.allProducts;
          this.allProducts.forEach((p: IProductMain) => {
            p.companyNames.split(',').forEach((pc: string) => {
              if (!this.allMyCompanies.find((c) => c.name === pc) && pc) {
                this.allMyCompanies.push({id: this.allMyCompanies.length, name: pc!});
              }
            })
          });
          this.allMyCompanies.sort((a, b) => a.name.localeCompare(b.name));
          this.doneLoading = true;
        });
    }
  }

  onCompanyChange(event: any) {
    if (event !== undefined) {
      this.allProducts = this.holdProducts.filter((p: IProductMain) => p.companyNames?.indexOf(event.name) > -1);
    } else {
      this.allProducts = this.holdProducts;
    }
    this.onPageChange(1);
  }

  onAddtoCart(id: string) {
    if (this.userRoles.includes(1) || (!this.userRoles.includes(3) && !this.userRoles.includes(10) && !this.userRoles.includes(2) && !this.userRoles.includes(12))) {
      this.shopApiService.saveCart({id: createGuid(), productId: id, currentStep: 1} as ICart)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((r: any) => {
          if (r.good) {
            this.router.navigate(['/pages/shop/cart', r.data.id]);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.tempProducts = this.allProducts.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
  }
}
